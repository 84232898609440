import * as React from 'react';

interface MainContentProps {
  children: React.ReactNode;
}

const MainContent: React.FunctionComponent<MainContentProps> = (
  props: MainContentProps
) => {
  return (
    <main className="main">
      {props.children}
    </main>
  );
};

export default MainContent;

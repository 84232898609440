import * as React from 'react';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {Button} from 'reactstrap';

import * as API from '../../api';
import {ContentContainer, Loading, TitleBar, Icon} from '../../common';
import {STWaterPump} from '../STWaterPump';
import {STNahmdee} from '../STNahmdee';
import {STEngine} from '../STEngine';

export const DeviceDetail: React.FC<{}> = () => {
  const [device, setDevice] = React.useState<any>({});
  const [data, setData] = React.useState<any>({});
  const [, setSocket] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(true);

  const {t} = useTranslation();
  const match = useParams();
  const history = useHistory();

  const {uuid} = match;

  const handleSocketMessage = React.useCallback((msg: any) => {
    const data = JSON.parse(msg.data);
    setData(data.payload);
  }, []);

  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      history.push(`/devices/${uuid}/edit`);
    },
    [history, uuid]
  );

  React.useEffect(() => {
    const url = new URL(window.APIEndpoint);
    url.protocol = url.protocol === 'https:' ? 'wss' : 'ws';
    url.username = 'dtbrowser';
    url.password = 'DTPass9182';

    const socket = new WebSocket(`${url.toString()}/ws/devices/${uuid}`);
    socket.onmessage = handleSocketMessage;
    setSocket(socket);

    (async () => {
      const res = await API.getDevice(uuid);

      setDevice(res.data);
      setLoading(false);
    })();

    return () => {
      socket.close();
    };
  }, [uuid]);

  if (loading) {
    return <Loading card />;
  }

  let content: any;

  if (loading) {
    content = <Loading card />;
  } else {
    content = <span>Loaded</span>;
  }

  switch (device.type) {
    case 'st-waterpump':
      content = <STWaterPump device={device} data={data} />;
      break;
    case 'st-nahmdee':
      content = <STNahmdee device={device} data={data} />;
      break;
    case 'st-engine':
      content = <STEngine device={device} data={data} />;
      break;
  }

  return (
    <ContentContainer>
      <TitleBar title={device.label.length > 0 ? device.label : t(device.type)}>
        <Button
          type="button"
          color="text-muted"
          className="p-0"
          onClick={handleClick}
        >
          <Icon name="Edit" />
        </Button>
      </TitleBar>
      {content}
    </ContentContainer>
  );
};

export default DeviceDetail;

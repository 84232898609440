import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap';

interface ConfirmModalProps {
  name?: string;
  isOpen?: boolean;
  message: string;
  variant?: any;
  onConfirm: (name?: string) => void;
  onCancel: (name?: string) => void;
}

export const ConfirmModal: React.SFC<ConfirmModalProps> = (
  props: ConfirmModalProps
) => {
  const {isOpen, name, onCancel, onConfirm} = props;

  const {t} = useTranslation();

  const handleConfirm = React.useCallback(() => {
    onConfirm && onConfirm(name);
  }, [name, onConfirm]);

  const handleCancel = React.useCallback(() => {
    onCancel && onCancel(name);
  }, [name, onCancel]);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal isOpen aria-labelledby="contained-modal-title-vcenter" centered>
      <ModalBody>{props.message}</ModalBody>
      <ModalFooter>
        <Button variant="secondary" onClick={handleCancel}>
          {t('confirm_cancel')}
        </Button>
        <Button variant={props.variant} onClick={handleConfirm}>
          {t('confirm_okay')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  message: '',
  variant: 'danger',
  onConfirm: () => null,
  onCancel: () => null,
};

export default ConfirmModal;

import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Row, Col} from 'reactstrap';

import * as API from '../../api';

import {
  ControlButton,
  ControlStartImage,
  ControlStopImage,
  ControlManualImage,
  ControlAutoImage,
  StatusPanel,
  StatusDot,
} from './Styles';

interface STEngineControlPanelProps {
  device: any;
  data: any;
  disabled?: boolean;
}

export const STEngineControlPanel: React.FC<STEngineControlPanelProps> = (
  props: STEngineControlPanelProps
) => {
  const {device, disabled} = props;
  const {t} = useTranslation();

  const handleControl = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const {id} = e.currentTarget;
      const comps = id.match(/control-(\S+)/);

      if (disabled) {
        return;
      }

      if (comps) {
        (async () => {
          await API.controlDevice({
            uuid: device.uuid,
            control: 'mode',
            value: comps[1],
          });
        })();
      }
    },
    [disabled, device.uuid]
  );

  const data = props.data || props.device.state;

  return (
    <>
      <Row>
        <Col xs={6} sm={6} md={3} className="mb-3 text-center">
          <ControlButton
            id="control-start"
            className="action-button mb-2"
            disabled={disabled}
            onClick={handleControl}
          >
            <ControlStartImage />
          </ControlButton>
          <span>{t('st-engine_control_start')}</span>
        </Col>
        <Col xs={6} sm={6} md={3} className="mb-3 text-center">
          <ControlButton
            id="control-manual"
            className="action-button mb-2"
            disabled={disabled}
            onClick={handleControl}
          >
            <ControlManualImage />
          </ControlButton>
          <span>{t('st-engine_control_manual')}</span>
        </Col>
        <Col xs={6} sm={6} md={3} className="mb-3 text-center">
          <ControlButton
            id="control-auto"
            className="action-button mb-2"
            disabled={disabled}
            onClick={handleControl}
          >
            <ControlAutoImage />
          </ControlButton>
          <span>{t('st-engine_control_auto')}</span>
        </Col>
        <Col xs={6} sm={6} md={3} className="mb-3 text-center">
          <ControlButton
            id="control-stop"
            className="action-button mb-2"
            disabled={disabled}
            onClick={handleControl}
          >
            <ControlStopImage />
          </ControlButton>
          <span>{t('st-engine_control_stop')}</span>
        </Col>
      </Row>
      <StatusPanel>
        <Row className="mb-3">
          <Col xs={7}>{t('st-engine_mode')}</Col>
          <Col xs={5}>{t(`st-engine_mode_${data.mode}`)}</Col>
        </Row>
        <Row className="mb-3">
          <Col xs={7}>{t('st-engine_hrs')}</Col>
          <Col xs={5}>{data.hrs}</Col>
        </Row>
        <Row className="mb-3">
          <Col xs={7}>{t('st-engine_ovsp')}</Col>
          <Col xs={5}>
            <StatusDot active={data.ovspd === 1} />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={7}>{t('st-engine_lwop')}</Col>
          <Col xs={5}>
            <StatusDot active={data.lwop === 1} />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={7}>{t('st-engine_lwfu')}</Col>
          <Col xs={5}>
            <StatusDot active={data.lwfu === 1} />
          </Col>
        </Row>
        <Row>
          <Col xs={7}>{t('st-engine_hicool')}</Col>
          <Col xs={5}>
            <StatusDot active={data.hicool === 1} />
          </Col>
        </Row>
      </StatusPanel>
    </>
  );
};

export default STEngineControlPanel;

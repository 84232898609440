import axios from 'axios';

export function getDevices() {
  return axios({
    method: 'GET',
    url: '/devices',
  });
}

export function getDevice(uuid: string) {
  return axios({
    method: 'GET',
    url: `/devices/${uuid}`,
  });
}

export function updateDevice(uuid: string, data: any) {
  return axios({
    method: 'PUT',
    url: `/devices/${uuid}`,
    data,
  });
}

export function controlDevice(payload: any) {
  return axios({
    method: 'POST',
    url: `/devices/${payload.uuid}/control`,
    data: payload,
  });
}

export function getDeviceData(uuid: string, params: any) {
  return axios({
    method: 'GET',
    url: `/devices/${uuid}/data`,
    params,
  });
}

import * as React from 'react';
import {useTranslation} from 'react-i18next';
import range from 'lodash/range';

import {Table} from 'reactstrap';

import {printHourTime, printNumber} from './helper';

interface STNahmdeeTableProps {
  data: any;
}

export const STNahmdeeTable: React.FC<STNahmdeeTableProps> = (
  props: STNahmdeeTableProps
) => {
  const {data} = props;

  const {t} = useTranslation();

  const buckets = data.aggregations.nahmdee.buckets;
  const columns: any = [[], []];

  buckets.forEach((b: any) => {
    const col = columns[b.key % 2];
    col.push(b);
  });

  return (
    <Table className="text-center" bordered size="sm">
      <thead>
        <tr>
          <th className="table-active" style={{width: '70px'}}>
            <b>{t('time')}</b>
          </th>
          <th>{t('value_oxygen')}</th>
          <th>{t('value_temperature')}</th>
          <th className="table-active" style={{width: '70px'}}>
            <b>{t('time')}</b>
          </th>
          <th>{t('value_oxygen')}</th>
          <th>{t('value_temperature')}</th>
        </tr>
      </thead>
      <tbody>
        {range(0, 24).map((i: number) => (
          <tr key={i}>
            <td className="table-active" style={{width: '70px'}}>
              <b>{printHourTime(i * 2)}</b>
            </td>
            <td>
              {columns[0][i].do.value
                ? printNumber(columns[0][i].do.value)
                : '-'}
            </td>
            <td>
              {columns[0][i].temperature.value
                ? printNumber(columns[0][i].temperature.value)
                : '-'}
            </td>
            <td className="table-active">
              <b>{printHourTime(i * 2 + 1)}</b>
            </td>
            <td>
              {columns[1][i].do.value
                ? printNumber(columns[1][i].do.value)
                : '-'}
            </td>
            <td>
              {columns[1][i].temperature.value
                ? printNumber(columns[1][i].temperature.value)
                : '-'}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default STNahmdeeTable;

import * as React from 'react';
import {useTranslation} from 'react-i18next';
import forEach from 'lodash/forEach';
import numeral from 'numeral';

import {
  Hint,
  XYPlot,
  LineMarkSeries,
  LineSeries,
  HorizontalGridLines,
  XAxis,
  YAxis,
  makeWidthFlexible,
  // @ts-ignore
} from 'react-vis';

import {STNahmdeeModeSelector} from './STNahmdeeModeSelector';
import {Container} from './Styles';
import {printHourTime} from './helper';

const FlexibleXYPlot = makeWidthFlexible(XYPlot);

interface STNahmdeeGraphProps {
  data: any;
}

export const STNahmdeeGraph: React.FC<STNahmdeeGraphProps> = (
  props: STNahmdeeGraphProps
) => {
  const {data} = props;
  const {t} = useTranslation();

  const [mode, setMode] = React.useState('do');
  const [nearest, setNearest] = React.useState<any>(null);

  const handleNearestX = React.useCallback((point: any) => {
    setNearest(point);
  }, []);

  if (!data) {
    return null;
  }

  const buckets = data.aggregations.nahmdee.buckets;

  let min: any = 0;
  let max: any = 100;
  const dt: any[] = [];

  forEach(buckets, (v, k: any) => {
    if (!v[mode] || !v[mode].value) {
      return;
    }

    const value = v[mode].value;

    if (min > value) {
      min = value;
    }

    if (max < value) {
      max = value;
    }

    dt.push({x: k, y: v[mode].value});
  });

  max = Math.ceil(max) + 1;

  return (
    <Container>
      <FlexibleXYPlot
        dontCheckIfEmpty
        height={500}
        xDomain={[0, 48]}
        yDomain={[0, max]}
      >
        <HorizontalGridLines />
        <YAxis />
        <XAxis tickFormat={printHourTime} />
        <LineMarkSeries data={dt} size={3} onNearestX={handleNearestX} />
        {nearest && (
          <LineSeries
            data={[{x: nearest.x, y: nearest.y}, {x: nearest.x, y: 0}]}
            stroke="#ccc"
          />
        )}
        {nearest && (
          <Hint
            value={nearest}
            align={{horizontal: Hint.TOP, vertical: Hint.ALIGN.TOP}}
          >
            <div className="rv-hint__content">
              <p>
                <b>{t('time_of_day')}:</b>&nbsp;
                {printHourTime(nearest.x)}
              </p>
              <p>
                <b>{t(`value_${mode}`)}:</b>&nbsp;
                {numeral(dt.filter((d: any) => d.x === nearest.x)[0].y).format(
                  '0.00'
                )}
              </p>
            </div>
          </Hint>
        )}
      </FlexibleXYPlot>
      <STNahmdeeModeSelector mode={mode} onModeChange={setMode} />
    </Container>
  );
};

export default STNahmdeeGraph;

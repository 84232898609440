import * as React from 'react';

import * as API from '../../api';
import AuthContext from './AuthContext';
import {reducer, initialState, setAuthenticated, setLoading} from './modules';

interface AuthContextProviderProps {
  children: any;
}

export const AuthContextProvider: React.FC<AuthContextProviderProps> = (
  props: AuthContextProviderProps
) => {
  const {children} = props;

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {authToken} = state;

  const handleLogout = React.useCallback(() => {
    window.localStorage.removeItem('authToken');

    setTimeout(() => {
      window.location.href = '/login';
    }, 1000);
  }, []);

  React.useEffect(() => {
    if (!authToken) {
      return;
    }

    (async () => {
      dispatch(setLoading(true));
      try {
        const res = await API.checkMe();
        dispatch(setAuthenticated(res.data));
      } catch (e) {
        // console.error(e);
      }
      dispatch(setLoading(false));
    })();
  }, [authToken]);

  const value = {
    ...state,
    dispatch,
    logout: handleLogout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;

import styled from 'styled-components';

export const Container = styled.div`
  &:after {
    content: "";
    display: table;
    clear: both;
  }
`;

export const FilterBar = styled.div`
  height: 48px;
  line-height: 48px;
  padding-left: 24px;
  border-bottom: 1px solid black;
`;

export const LeftPanel = styled.div`
  float: left;
  width: 30%;
`;

export const RightPanel = styled.div`
  float: left;
  width: 70%;
`;

export const Tiles = styled.div`
  padding: 20px 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const Tile = styled.div`
  flex: 1 1 auto;
  ...
  display: flex;

  & > div {
    flex: 1 0 auto;
  }
`

import produce from 'immer';
import { Action } from '../../util';
import { types } from './types';

interface State {
  devices: any;
  deviceList: any;
}

const initialState: State = {
  devices: {},
  deviceList: {
    data: []
  }
};

export const reducer = (state = initialState, action: Action) =>
  produce(state, (draft: State) => {
    switch (action.type) {
      case types.GET_DEVICES_SUCCESS:
        draft.deviceList.page = 1;
        draft.deviceList.data = action.payload.data;
        break;
      case types.GET_DEVICE_SUCCESS:
        draft.devices[action.payload.data.uuid] = action.payload.data;
        break;
    }
  });

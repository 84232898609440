export const types = {
  GET_STATIONS_REQUEST: 'station/GET_STATIONS_REQUEST',
  GET_STATIONS_SUCCESS: 'station/GET_STATIONS_SUCCESS',
  GET_STATIONS_FAILURE: 'station/GET_STATIONS_FAILURE',
  GET_STATION_REQUEST: 'station/GET_STATION_REQUEST',
  GET_STATION_SUCCESS: 'station/GET_STATION_SUCCESS',
  GET_STATION_FAILURE: 'station/GET_STATION_FAILURE',
  CREATE_STATION_REQUEST: 'station/CREATE_STATION_REQUEST',
  CREATE_STATION_SUCCESS: 'station/CREATE_STATION_SUCCESS',
  CREATE_STATION_FAILURE: 'station/CREATE_STATION_FAILURE',
  UPDATE_STATION_REQUEST: 'station/UPDATE_STATION_REQUEST',
  UPDATE_STATION_SUCCESS: 'station/UPDATE_STATION_SUCCESS',
  UPDATE_STATION_FAILURE: 'station/UPDATE_STATION_FAILURE',
  GET_STATION_ACTIVITIES_REQUEST: 'station/GET_STATION_ACTIVITIES_REQUEST',
  GET_STATION_ACTIVITIES_SUCCESS: 'station/GET_STATION_ACTIVITIES_SUCCESS',
  GET_STATION_ACTIVITIES_FAILURE: 'station/GET_STATION_ACTIVITIES_FAILURE',
  STATION_ACTIVITY_INIT: 'station/STATION_ACTIVITY_INIT'
};

import * as React from 'react';
import startsWith from 'lodash/startsWith';

import * as API from '../../api';

import {HomeStationsTopbar} from './HomeStationsTopbar';
import {HomeStationsList} from './HomeStationsList';
import {HomeStationsMap} from './HomeStationsMap';

import {Container} from './HomeStationsStyles';

export const HomeStations: React.FC<{}> = () => {
  const [loading, setLoading] = React.useState(true);
  const [stations, setStations] = React.useState([]);
  const [hoveredStation, setHoveredStation] = React.useState<number[]>([]);
  const [search, setSearch] = React.useState('');

  const handleStationHover = React.useCallback(
    (station: any, hover: boolean) => {
      if (station && hover) {
        if (station.latitude === 0 && station.longitude === 0) {
          return;
        }

        setHoveredStation([station.id]);
      } else {
        if (!hoveredStation) {
          return;
        }
      }
    },
    [hoveredStation]
  );

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      const stations = await API.getStations();
      setStations(stations);
      setLoading(false);
    })();
  }, []);

  let sts = stations;

  if (search.length) {
    sts = sts.filter((s: any) => startsWith(s.name.toLowerCase(), search));
  }

  return (
    <Container>
      <HomeStationsTopbar onSearch={setSearch} />
      <HomeStationsList
        loading={loading}
        stations={sts}
        onStationHover={handleStationHover}
      />
      <HomeStationsMap stations={stations} highlighted={hoveredStation} />
    </Container>
  );
};

export default HomeStations;

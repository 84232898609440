import styled from 'styled-components';

export const DataBoxIcon = styled.div`
  display: flex;
  width: 30%;
  max-height: 100%;
  padding: 15px;
  align-items: center;
  background-color: #ebf5ff;
  text-align: center;

  > .icon {
    margin: auto;
  }
`;

export const DataBoxContent = styled.div`
  width: 100%;
  max-height: 100%;
  padding: 15px;
  font-size: 12px;

  > h6 {
    font-size: 14px;
  }

  > p {
    margin: 0;
    font-size: 12px;
  }
`;

export const DataBox = styled.div`
  display: flex;
  min-height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.125);

  ${DataBoxIcon} + ${DataBoxContent} {
    width: 70%;
  }
`;

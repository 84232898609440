import * as React from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

import {Card, CardBody, CardHeader, Table} from 'reactstrap';

interface InfoPanelProps {
  device: any;
  data: any;
}

export const InfoPanel: React.FC<InfoPanelProps> = (props: InfoPanelProps) => {
  const {data, device} = props;

  const {t} = useTranslation();

  return (
    <Card className="h-100">
      <CardHeader>
        <span className="font-weight-bold">{t('general_information')}</span>
      </CardHeader>
      <CardBody>
        <Table className="m-0" borderless size="md">
          <tbody>
            <tr>
              <td>{t('device_last_connected_at')}</td>
              <td>
                {moment(device.lastConnectedAt).format('DD/MM/YY HH:mm')}
              </td>
            </tr>
            <tr>
              <td>{t('st-waterpump_tank_depth')}</td>
              <td>{data.tankDepth}</td>
            </tr>
            <tr>
              <td>{t('st-waterpump_tank_area')}</td>
              <td>{data.tankArea}</td>
            </tr>
            <tr>
              <td>{t('st-waterpump_auto_mode')}</td>
              <td>
                <span className={`led led-${data.autoMode ? 'success' : 'idle'} mr-3`} />
                {data.autoMode ? t('on') : t('off')}
              </td>
            </tr>
            <tr>
              <td>{t('st-waterpump_auto_sorting_mode')}</td>
              <td>
                <span className={`led led-${data.autoSorting ? 'success' : 'idle'} mr-3`} />
                {data.autoSorting ? t('on') : t('off')}
              </td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default InfoPanel;

import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import isUrl from 'is-url';
import * as Promise from 'bluebird';
import isObject from 'lodash/isObject';
import humps from 'humps';

interface AxiosOptions {
  baseUrl: string;
  loginUrl: string;
  authKey: string;
}

export function configureAxios(options: AxiosOptions) {
  axios.defaults.baseURL = options.baseUrl;

  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    const {headers} = config;
    const token = window.localStorage.getItem(options.authKey);

    const hasToken = token && token.length;

    const url = config.url || '';

    const isOtherUrl = url && isUrl(url) && !url.startsWith(options.baseUrl);

    if (!isOtherUrl && hasToken) {
      headers.Authorization = token;
    }

    return config;
  }, undefined);

  axios.interceptors.response.use(
    (res: AxiosResponse) => {
      if (isObject(res.data)) {
        res.data = humps.camelizeKeys(res.data);
      }

      return res;
    },
    error => {
      const {status} = error.response;

      console.log(error.response);

      if (status === 401 || status === 403) {
        window.localStorage.removeItem(options.authKey);

        const {pathname} = window.location;

        setTimeout(() => {
          if (pathname !== options.loginUrl) {
            window.location.href = options.loginUrl;
          }
        }, 1000);
      }

      return Promise.reject(error);
    }
  );
}

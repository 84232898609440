import * as React from 'react';

interface WaterLevelGauageProps {
  waterLevel: number;
  shutdownLevel?: number;
  operatingLevel?: number;
}

export const WaterLevelGauage: React.FC<WaterLevelGauageProps> = (
  props: WaterLevelGauageProps
) => {
  const {waterLevel, shutdownLevel, operatingLevel} = props;

  return (
    <div className="water-level h-100">
      <div className="water-level-inner">
        <div className="water-level-bg" />
        <div className="water-level-bar" style={{height: `${waterLevel}%`}}>
          <div className="water-level-number">{waterLevel}%</div>
        </div>
        <div className="water-level-indicator">
          {shutdownLevel &&
            <div className="no-load" style={{top: `${100 - shutdownLevel}%`}}>
              {shutdownLevel}%
            </div>
          }
          {operatingLevel &&
            <div style={{top: `${100 - operatingLevel}%`}}>
              {operatingLevel}%
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default WaterLevelGauage;

import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {ContentContainer, TitleBar} from '../../common';
import * as API from '../../api';

import {UserForm} from '../UserForm';

export const UserNewPage: React.FC<{}> = () => {
  const history = useHistory();
  const {t} = useTranslation();

  const handleSubmit = React.useCallback(
    (values: any) => {
      (async () => {
        try {
          const res = await API.createOrganizationUser(values);
          const {id} = res.data;

          history.replace(`/organization/users/${id}/edit`);
        } catch (e) {
          // console.log(e);
        }
      })();
    },
    [history]
  );

  return (
    <ContentContainer>
      <TitleBar title={t('organization_users_new')} />
      <hr />
      <UserForm onSubmit={handleSubmit} />
    </ContentContainer>
  );
};

export default UserNewPage;

import produce from 'immer';
import startsWith from 'lodash/startsWith';

import { Action } from '../../util';
import { types } from './types';

interface State {
  stationIndex: any;
  stationActivity: any;
  stationNew: any;
  stationDetail: any;
  stationEdit: any;
}

const initialState: State = {
  stationIndex: {
    data: [],
    loading: true
  },
  stationActivity: {
    data: null,
    loading: true
  },
  stationNew: {
    disabled: false
  },
  stationDetail: {
    data: null,
    loading: true
  },
  stationEdit: {
    data: null,
    loading: true
  }
};

export const reducer = (state = initialState, action: Action) =>
  produce(state, (draft: State) => {
    if (!startsWith(action.type, 'station')) {
      return;
    }
    switch (action.type) {
      case types.GET_STATIONS_REQUEST:
        draft[action.sender].loading = true;
        break;
      case types.GET_STATIONS_SUCCESS:
        draft[action.sender].loading = false;
        draft[action.sender].data = action.payload.data;
        break;
      case types.GET_STATIONS_FAILURE:
        draft[action.sender].loading = false;
        break;
      case types.GET_STATION_REQUEST:
        draft[action.sender].loading = true;
        break;
      case types.GET_STATION_SUCCESS:
        draft[action.sender].loading = false;
        draft[action.sender].data = action.payload.data;
        break;
      case types.GET_STATION_FAILURE:
        draft[action.sender].loading = false;
        break;
      case types.CREATE_STATION_REQUEST:
        draft[action.sender].disabled = true;
        break;
      case types.CREATE_STATION_SUCCESS:
        draft[action.sender].disabled = false;
        break;
      case types.UPDATE_STATION_REQUEST:
        draft[action.sender].disabled = true;
        break;
      case types.UPDATE_STATION_SUCCESS:
        draft[action.sender].disabled = false;
        break;
      case types.UPDATE_STATION_FAILURE:
        draft[action.sender].disabled = false;
        break;
      case types.CREATE_STATION_FAILURE:
        draft[action.sender].disabled = false;
        break;
      case types.GET_STATION_ACTIVITIES_REQUEST:
        draft[action.sender].loading = true;
        break;
      case types.GET_STATION_ACTIVITIES_SUCCESS:
        draft.stationActivity.loading = false;

        if (draft.stationActivity.data) {
          const { activities } = draft.stationActivity.data;
          activities.hits.push(...action.payload.data.activities.hits);
          draft.stationActivity.data.page = action.payload.data.page;
        } else {
          draft.stationActivity.data = action.payload.data;
        }
        break;
      case types.STATION_ACTIVITY_INIT:
        draft.stationActivity.data = null;
        break;
    }
  });

import * as React from 'react';
import {MapPin} from 'react-feather';

interface MapPinIconProps {
  highlighted?: boolean;
  lat: number;
  lng: number;
}

export const MapPinIcon: React.FC<MapPinIconProps> = (
  props: MapPinIconProps
) => {
  return <MapPin fill="red" color="white" size={props.highlighted ? 48 : 36} />;
};

export default MapPinIcon;

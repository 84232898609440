import * as React from 'react';
import merge from 'lodash/merge';
import {Formik, Form, FormikProps} from 'formik';

import {Button, Col, FormGroup, Label, Input} from 'reactstrap';

interface StationFormProps {
  mode: 'new' | 'edit';
  disabled: boolean;
  values?: any;
  onSubmit: (values: FormValues) => void;
}

export interface FormValues {
  name: string;
  code: string;
  latitude: number;
  longitude: number;
  staffName: string;
  staffTel: string;
}

const initialvalues = {
  name: '',
  code: '',
  latitude: 0.0,
  longitude: 0.0,
  staffName: '',
  staffTel: '',
};

export const StationForm: React.FunctionComponent<StationFormProps> = (
  props: StationFormProps
) => {
  const values = merge({}, initialvalues, props.values);

  return (
    <Formik
      initialValues={values}
      onSubmit={props.onSubmit}
      render={(fprops: FormikProps<FormValues>) => (
        <Form>
          <div className="p-3 mb-3 border rounded">
            <FormGroup row>
              <Label for="name" sm={3} className="font-weight-bold">
                ชื่อสถานี
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  name="name"
                  defaultValue={values.name}
                  onChange={fprops.handleChange}
                  disabled={props.disabled}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="code" sm={3} className="font-weight-bold">
                ชื่อย่อสถานี
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  name="code"
                  defaultValue={values.code}
                  onChange={fprops.handleChange}
                  disabled={props.disabled || props.mode === 'edit'}
                />
              </Col>
            </FormGroup>
          </div>
          <div className="p-3 mb-3 border rounded">
            <FormGroup row>
              <Label for="latitude" sm={3}>
                ตำแหน่ง Latitude
              </Label>
              <Col sm={9}>
                <Input
                  type="number"
                  id="latitude"
                  name="latitude"
                  step="any"
                  defaultValue={values.latitude}
                  disabled={props.disabled}
                  onChange={fprops.handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="longitude" sm={3}>
                ตำแหน่ง Longitude
              </Label>
              <Col sm={9}>
                <Input
                  type="number"
                  id="longitude"
                  name="longitude"
                  step="any"
                  defaultValue={values.longitude}
                  disabled={props.disabled}
                  onChange={fprops.handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="latitude" sm={3}>
                ผู้ดูแลบ่อ
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  id="staffName"
                  name="staffName"
                  defaultValue={values.staffName}
                  disabled={props.disabled}
                  onChange={fprops.handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="longitude" sm={3}>
                เบอร์ติดต่อ
              </Label>
              <Col sm={9}>
                <Input
                  type="tel"
                  id="staffTel"
                  name="staffTel"
                  defaultValue={values.staffTel}
                  disabled={props.disabled}
                  onChange={fprops.handleChange}
                />
              </Col>
            </FormGroup>
          </div>
          <Button className="mr-2" color="primary" disabled={props.disabled}>
            บันทึก
          </Button>
        </Form>
      )}
    />
  );
};

export default StationForm;

import * as React from 'react';
import GoogleMapReact from 'google-map-react';

import MapPinIcon from './MapPinIcon';

const key = 'AIzaSyBYkXd7y32PbX5v0GLXrl-8KsEqG6TqECs';

interface Position {
  lat: number;
  lng: number;
}

interface StationMapProps {
  markers: Position[];
  defaultCenter?: Position;
  stations: any[];
  highlighted?: any[];
}

const BKKDefaultCenter = {lat: 13.7563, lng: 100.5018};
const isValidPosition = (p?: Position) => p && p.lat !== 0 && p.lng !== 0;

export const StationMap: React.FC<StationMapProps> = (
  props: StationMapProps
) => {
  const {defaultCenter} = props;

  const stations: any[] = [];
  const highlighted: any[] = props.highlighted || [];

  props.stations.forEach((s: any) => {
    if (s.latitude === 0 || s.longitude === 0) {
      return;
    }

    stations.push(s);
  });

  const mapProps: any = {};

  if (highlighted.length) {
    const s = stations.filter((s: any) => highlighted.indexOf(s.id) !== -1);

    if (s.length) {
      mapProps.center = {lat: s[0].latitude, lng: s[0].longitude};
    }
  }

  if (props.defaultCenter) {
    mapProps.defaultCenter = defaultCenter;
  }

  if (!mapProps.defaultcenter || !isValidPosition(props.defaultCenter)) {
    mapProps.defaultCenter = BKKDefaultCenter;
  }

  return (
    <GoogleMapReact bootstrapURLKeys={{key}} defaultZoom={11} {...mapProps}>
      {stations.map((s: any) => (
        <MapPinIcon
          key={s.id}
          lat={s.latitude}
          lng={s.longitude}
          highlighted={highlighted.indexOf(s.id) !== -1}
        />
      ))}
    </GoogleMapReact>
  );
};

export default StationMap;

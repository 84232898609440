import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';

import {Button, Form, FormGroup, Input, Label} from 'reactstrap';

interface UserFormProps {
  values?: any;
  onSubmit: (values: any) => void;
}

export const UserForm: React.FC<UserFormProps> = (props: UserFormProps) => {
  const {onSubmit, values} = props;

  const {t} = useTranslation();
  const {handleSubmit, register, getValues} = useForm({
    defaultValues: values,
  });

  const submitFn = React.useCallback(
    (values: any) => {
      onSubmit(values);
    },
    [onSubmit]
  );

  const roles = getValues().roles || [];
  const isAdmin = roles.indexOf('ROLE_ORG_ADMIN') !== -1;

  return (
    <Form onSubmit={handleSubmit(submitFn)}>
      <FormGroup>
        <Label for="firstName">{t('first_name')}</Label>
        <Input
          id="firstName"
          type="text"
          innerRef={register}
          name="firstName"
        />
      </FormGroup>
      <FormGroup>
        <Label for="lastName">{t('last_name')}</Label>
        <Input id="lastName" type="text" innerRef={register} name="lastName" />
      </FormGroup>
      <FormGroup>
        <Label for="email">{t('email')}</Label>
        <Input id="email" type="text" innerRef={register} name="email" />
      </FormGroup>
      <FormGroup>
        <Label for="password">{t('password')}</Label>
        <Input
          id="password"
          type="password"
          innerRef={register}
          name="password"
        />
      </FormGroup>
      <FormGroup>
        <Label for="confirmPassword">{t('confirm_password')}</Label>
        <Input
          id="confirmPassword"
          type="password"
          innerRef={register}
          name="confirmPassword"
        />
      </FormGroup>
      <FormGroup>
        <div className="custom-checkbox custom-control">
          <input
            type="checkbox"
            id="role-org-admin"
            name="isAdmin"
            className="custom-control-input"
            ref={register}
            defaultChecked={isAdmin}
          />
          <label htmlFor="role-org-admin" className="custom-control-label">
            Organiztion Admin
          </label>
        </div>
      </FormGroup>
      <Button type="submit" color="primary">
        {t('save')}
      </Button>
    </Form>
  );
};

export default UserForm;

export const types = {
  LOGIN_REQUEST: 'auth/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'auth/LOGIN_SUCCESS',
  LOGIN_FAILURE: 'auth/LOGIN_FAILURE',
  LOGOUT_REQUEST: 'auth/LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'auth/LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'auth/LOGOUT_FAILURE',
  CHECK_ME_REQUEST: 'auth/CHECK_ME_REQUEST',
  CHECK_ME_SUCCESS: 'auth/CHECK_ME_SUCCESS',
  CHECK_ME_FAILURE: 'auth/CHECK_ME_FAILURE'
};

import styled, {css} from 'styled-components';

import {Row as BaseRow} from 'reactstrap';

import startImage from 'assets/images/st-engine-start-button.svg';
import stopImage from 'assets/images/st-engine-stop-button.svg';
import manualImage from 'assets/images/st-engine-manual-button.svg';
import autoImage from 'assets/images/st-engine-auto-button.svg';

import {Panel} from '../../common';

export const StatusPanel = styled(Panel)`
  padding: 30px 0;
`;

export const ControlImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
`;

interface StatusDotProps {
  active: boolean;
}

export const StatusDot = styled.div<StatusDotProps>`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${props => (props.active ? 'red' : 'grey')};
`;

export const StatusGreenDot = styled.div<StatusDotProps>`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${props => (props.active ? 'green' : 'grey')};
`;

export const Row = styled(BaseRow)`
  margin-bottom: 15px;
`;

export const ControlStartImage = styled(ControlImage)`
  background-color: green;
  background-image: url(${startImage});
`;

export const ControlStopImage = styled(ControlImage)`
  background-color: red;
  background-image: url(${stopImage});
`;

export const ControlManualImage = styled(ControlImage)`
  background-color: #000;
  background-image: url(${manualImage});
`;

export const ControlAutoImage = styled(ControlImage)`
  background-color: #000;
  background-image: url(${autoImage});
`;

interface ControlButtonProps {
  disabled?: boolean;
}

export const ControlButton = styled.button<ControlButtonProps>`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  margin-bottom: 20px;
  align-items: bottom;
  background-size: contain;
  text-align: center;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  p {
    width: 100%;
    margin-top: auto;
    text-align: center;
  }

  > div {
    box-shadow: ${props => (props.disabled ? '0px 0px 8px 3px rgba(61,61,61,1)' : '0 3px #aaa')};
  }
`;

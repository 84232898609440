import {themeConstants as c} from './themeConstants';

export const defaultTheme = {
  primary: 'blue',
  borderColor: '#484848',

  contentPadding: `0px ${c.contentPadding}`,
  
  border: '1px solid #ebebeb',
};

import * as React from 'react';
import {useTranslation} from 'react-i18next';

import {Button} from 'reactstrap';

interface ErrorPageProps {
  error: any;
}

const returnPath = '/home/stations';

export const ErrorPage: React.FC<ErrorPageProps> = (props: ErrorPageProps) => {
  const {error} = props;

  const {t} = useTranslation();

  const handleClick = () => {
    window.location.href = returnPath;
  };

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-md-6 col-xl-6 my-5">
        <div className="text-center">
          <h6 className="text-uppercase text-muted mb-4">
            {t('error_unexpected')}
          </h6>
          <h1 className="display-4 mb-3">{t('error_unexpected_message')}</h1>
          <p className="text-muted mb-4">
            {t('error_unexpected_instruction')}
            <br />
            {t('error_message', {message: error.message})}
          </p>
          <Button color="primary" onClick={handleClick}>
            {t('button_return_home')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;

import * as React from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {Button, Card, CardBody, CardHeader} from 'reactstrap';

import * as API from '../../api';
import {Icon, Loading} from '../../common';

import {STNahmdeeGraph} from './STNahmdeeGraph';
import {STNahmdeeTable} from './STNahmdeeTable';
import {STNahmdeeToolbar} from './STNahmdeeToolbar';

import STNahmdeeDownloadModal from './STNahmdeeDownloadModal';

import {DateRange} from './helper';

interface STNahmdeeProps {
  device: any;
  data?: any;
}

const start = moment()
  .startOf('day')
  .toDate();

const end = moment()
  .endOf('day')
  .toDate();

const initialDateRange = {
  from: start,
  to: end,
};

export const STNahmdee: React.FC<STNahmdeeProps> = (props: STNahmdeeProps) => {
  const {device} = props;

  const {t} = useTranslation();
  const [dateRange, setDateRange] = React.useState(initialDateRange);
  const [view, setView] = React.useState('table');
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<any>(null);
  const [modal, setModal] = React.useState(false);

  const handleDateRange = React.useCallback(
    (dr: DateRange) => setDateRange(dr),
    []
  );

  const handleDownload = React.useCallback(() => {
    setModal(true);
  }, []);

  const handleModalToggle = React.useCallback(() => {
    setModal(!modal);
  }, [modal]);

  React.useEffect(() => {
    setLoading(true);

    (async () => {
      const res = await API.getDeviceData(device.uuid, {
        from: moment(dateRange.from).format('YYYY-MM-DD'),
        to: moment(dateRange.to).format('YYYY-MM-DD'),
        type: 'aggregated',
      });
      setData(res.data);
      setLoading(false);
    })();
  }, [dateRange]);

  let content: any;

  if (!loading) {
    switch (view) {
      case 'graph':
        content = <STNahmdeeGraph data={data} />;
        break;
      case 'table':
        content = <STNahmdeeTable data={data} />;
        break;
    }
  } else {
    content = <Loading />;
  }

  return (
    <div className="nahmdee-device">
      <Card>
        <CardHeader className="d-flex align-items-center">
          <b className="flex-fill">{t('general_information')}</b>
          <Button
            color="text-muted"
            className="float-right py-0"
            onClick={handleDownload}
          >
            <Icon name="Download" />
          </Button>
        </CardHeader>
        <CardBody>
          <STNahmdeeToolbar
            dateRange={dateRange}
            view={view}
            onDateRangeChange={handleDateRange}
            onViewChange={setView}
          />
          {content}
        </CardBody>
        <STNahmdeeDownloadModal isOpen={modal} toggle={handleModalToggle} />
      </Card>
    </div>
  );
};

function parsePumpEvent(property: string, value: any) {
  const comps = property.match(/pump(\d+)(\S+)/);

  if (!comps) {
    return null;
  }

  const order = comps[1];
  const activity = comps[2].toLowerCase();

  let output = null;

  switch (activity) {
    case 'online':
      output = value
        ? {
            icon: 'Wifi',
            intent: 'primary',
            content: `ปั๊มตัวที่ ${order} ออนไลน์`,
          }
        : {
            icon: 'WifiOff',
            intent: 'info',
            content: `ปั๊มตัวที่ ${order} ออฟไลน์`,
          };
      break;
    case 'on':
      output = value
        ? {
            icon: 'Play',
            intent: 'success',
            content: `ปั๊มตัวที่ ${order} ทำงาน`,
          }
        : {
            icon: 'Pause',
            intent: 'danger',
            content: `ปั๊มตัวที่ ${order} หยุดทำงาน`,
          };
      break;
    case 'overload':
      output = value
        ? {
            icon: 'AlertTriangle',
            intent: 'danger',
            content: `ปั๊มตัวที่ ${order} ตรวจเจอ overload`,
          }
        : {
            icon: 'CheckSquare',
            intent: 'info',
            content: `ปั๊มตัวที่ ${order} overload กลับเป็นปกติ`,
          };
      break;
    case 'phaseprot':
      output = value
        ? {
            icon: 'CloudLightning',
            intent: 'danger',
            content: `ปั๊มตัวที่ ${order} ตรวจเจอ phase protection`,
          }
        : {
            icon: 'CheckSquare',
            intent: 'info',
            content: `ปั๊มตัวที่ ${order} phase protection กลับเป็นปกติ`,
          };
      break;
    case 'waterleak':
      output = value
        ? {
            icon: 'Droplet',
            intent: 'danger',
            content: `ปั๊มตัวที่ ${order} ตรวจเจอน้ำรั่ว`,
          }
        : {
            icon: 'CheckSquare',
            intent: 'info',
            content: `ปั๊มตัวที่ ${order} น้ำรั่วกลับเป็นปกติ`,
          };
      break;
    case 'moisture':
      output = value
        ? {
            icon: 'Wind',
            intent: 'danger',
            content: `ปั๊มตัวที่ ${order} ตรวจเจอความชื้น`,
          }
        : {
            icon: 'CheckSquare',
            intent: 'info',
            content: `ปั๊มตัวที่ ${order} ความชื้นกลับเป็นปกติ`,
          };
      break;
    case 'earthLeak':
      output = value
        ? {
            icon: 'Zap',
            intent: 'danger',
            content: `ปั๊มตัวที่ ${order} ตรวจเจอ earth leak`,
          }
        : {
            icon: 'CheckSquare',
            intent: 'info',
            content: `ปั๊มตัวที่ ${order} earth leak กลับเป็นปกติ`,
          };
      break;
    case 'criticalthermal':
      output = value
        ? {
            icon: 'Thermometer',
            intent: 'danger',
            content: `ปั๊มตัวที่ ${order} อุณหภูมิสูงผิดปกติ`,
          }
        : {
            icon: 'CheckSquare',
            intent: 'info',
            content: `ปั๊มตัวที่ ${order} อุณหภูมิกลับเป็นปกต`,
          };
      break;
    case 'criticalbeartemp':
      output = value
        ? {
            icon: 'Thermometer',
            intent: 'danger',
            content: `ปั๊มตัวที่ ${order} อุณหภูมิแบริ่งสูงผิดปกติ`,
          }
        : {
            icon: 'CheckSquare',
            intent: 'info',
            content: `ปั๊มตัวที่ ${order} อุณหภูมิแบริ่งกลับเป็นปกติ`,
          };
      break;
  }

  return output;
}

export function getActivityObj(property: string, value: any) {
  let output = null;

  switch (property) {
    case 'autoMode':
      output = value
        ? {
            icon: 'Info',
            intent: 'info',
            content: 'โหมดการทำงานอัตโนมัติเปิด',
          }
        : {
            icon: 'AlertCircle',
            intent: 'warning',
            content: 'โหมดการทำงานอัตโนมัติปิด',
          };
      break;
    case 'autoSorting':
      output = value
        ? {
            icon: 'Info',
            intent: 'info',
            content: 'โหมดการหมุนเวียนปั๊มเปิด',
          }
        : {
            icon: 'AlertCircle',
            intent: 'warning',
            content: 'โหมดการหมุนเวียนปั๊มปิด',
          };
      break;
    case 'gateOpen':
      output = value
        ? {
            icon: 'ArrowUpCircle',
            intent: 'success',
            content: 'ประตูน้ำเปิด',
          }
        : {
            icon: 'StopCircle',
            intent: 'danger',
            content: 'ประตูน้ำหยุด',
          };
      break;
    case 'gateClose':
      output = value
        ? {
            icon: 'ArrowDownCircle',
            intent: 'warning',
            content: 'ประตูน้ำปิด',
          }
        : {
            icon: 'StopCircle',
            intent: 'danger',
            content: 'ประตูน้ำหยุด',
          };
      break;
    case 'gateOnline':
      output = value
        ? {
            icon: 'Wifi',
            intent: 'primary',
            content: 'ประตูน้ำออนไลน์',
          }
        : {
            icon: 'WifiOff',
            intent: 'danger',
            content: 'ประตูน้ำออฟไลน์',
          };
      break;
    case 'noOfPumps':
      output = {
        icon: 'Database',
        intent: 'info',
        content: `จำนวนปั๊มในระบบเปลี่ยนเป็น ${value}`,
      };
      break;
    case 'noLoadLevel':
      output = {
        icon: 'Database',
        intent: 'info',
        content: `ระดับน้ำปิดเครื่องเปลี่ยนเป็น ${value}`,
      };
      break;
    case 'level1':
      output = {
        icon: 'Database',
        intent: 'info',
        content: `ระดับน้ำควบคุมตัวที่ 1 เปลี่ยนเป็น ${value}`,
      };
      break;
    case 'level2':
      output = {
        icon: 'Database',
        intent: 'info',
        content: `ระดับน้ำควบคุมตัวที่ 2 เปลี่ยนเป็น ${value}`,
      };
      break;
    case 'level3':
      output = {
        icon: 'Database',
        intent: 'info',
        content: `ระดับน้ำควบคุมตัวที่ 3 เปลี่ยนเป็น ${value}`,
      };
      break;
    default:
      output = parsePumpEvent(property, value);
  }

  return output;
}

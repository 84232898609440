import * as React from 'react';

import {Loadable} from '../../common';

import {HomeStationsListItem} from './HomeStationsListItem';
import {ListContainer} from './HomeStationsStyles';

interface HomeStationsListProps {
  loading: boolean;
  stations: any[];
  onStationHover: (station: any, hover: boolean) => void;
}

export const HomeStationsList: React.FC<HomeStationsListProps> = (
  props: HomeStationsListProps
) => {
  const {loading, stations} = props;

  return (
    <ListContainer>
      <Loadable loading={loading}>
        {() => (
          <React.Fragment>
            {stations.map((s: any) => (
              <HomeStationsListItem
                key={s.id}
                station={s}
                onHover={props.onStationHover}
              />
            ))}
          </React.Fragment>
        )}
      </Loadable>
    </ListContainer>
  );
};

export default HomeStationsList;

import styled from 'styled-components';

import {Card, Container} from 'reactstrap';

export const AuthWrapper = styled.div`
  background-color: #f8f9fa;
  height: 100vh;
`;

export const LoginContainer = styled(Container)`
  display: flex;
  height: 100vh;
  align-items: center;
`;

export const LoginCard = styled(Card)`
  width: 100%;
  max-width: 480px;
  margin: auto;
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
`;

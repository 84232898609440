import * as React from 'react';
import {useTranslation} from 'react-i18next';
import * as API from '../../api';
import {ControlButton, ControlStartImage, ControlStopImage} from '../STEngine';

interface PumpControlPanelProps {
  pump: string;
  uuid: string;
}

export const PumpControlPanel: React.FC<PumpControlPanelProps> = (
  props: PumpControlPanelProps
) => {
  const {uuid, pump} = props;
  const {t} = useTranslation();

  const handlePowerAction = React.useCallback(
    (action: string) => {
      let cmd: any;

      if (action === 'start') {
        cmd = {manualStart: true};
      } else if (action === 'stop') {
        cmd = {manualStop: true};
      }

      if (!cmd) {
        return;
      }

      (async () => {
        await API.controlDevice({
          uuid: uuid,
          control: 'pumps',
          value: {[parseInt(pump, 10)]: cmd},
        });
      })();
    },
    [uuid, pump]
  );
  return (
    <div className="row">
      <div className="col-6 mb-3 text-center">
        <ControlButton className="action-button" onClick={() => handlePowerAction('start')}>
          <ControlStartImage className="mb-2" />
        </ControlButton>
        <span>{t('st-waterpump_manual_start')}</span>
      </div>
      <div className="col-6 mb-3 text-center">
        <ControlButton className="action-button" onClick={() => handlePowerAction('stop')}>
          <ControlStopImage className="mb-2" />
        </ControlButton>
        <span>{t('st-waterpump_manual_stop')}</span>
      </div>
    </div>
  );
};

export default PumpControlPanel;

import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Icon} from '../../common';

import {
  Topbar,
  TopbarLeftPanel,
  TopbarRightPanel,
  TopbarNewButton,
  SearchInput,
} from './HomeStationsStyles';

interface HomeStationsTopbarProps {
  onSearch: (search: string) => void;
}

export const HomeStationsTopbar: React.FC<HomeStationsTopbarProps> = (
  props: HomeStationsTopbarProps
) => {
  const {t} = useTranslation();
  const history = useHistory();

  const handleNewStation = React.useCallback(() => {
    history.push('/stations/new');
  }, []);

  const handleSearchChange = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const {value} = e.currentTarget;
      props.onSearch(value);
    },
    []
  );

  return (
    <Topbar>
      <TopbarLeftPanel>
        <Icon name="Search" />
        <SearchInput
          placeholder={t('station_search_placeholder')}
          onChange={handleSearchChange}
        />
      </TopbarLeftPanel>
      <TopbarRightPanel>
        <TopbarNewButton className="d-none btn btn-primary" onClick={handleNewStation}>
          {t('new_station')}
        </TopbarNewButton>
      </TopbarRightPanel>
    </Topbar>
  );
};

export default HomeStationsTopbar;

import * as React from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Table,
  Button,
} from 'reactstrap';
import WaterLevelGauage from './WaterLevelGauage';
import {CanvasGauge, Icon} from '../../common';

import * as API from '../../api';

import {STEngineControlPanel} from './STEngineControlPanel';

interface STEngineProps {
  device: any;
  data: any;
}

export const STEngine: React.FC<STEngineProps> = (props: STEngineProps) => {
  const {t} = useTranslation();
  const {lastConnectedAt, uuid} = props.device;

  const connected = moment().diff(moment(lastConnectedAt), 'minute') < 5;
  const data = props.data || props.device.state;
  const waterLevel = data.water_level ? data.water_level : undefined;
  const shutdownLevel = data.shutdown_level ? data.shutdown_level : undefined;
  const operatingLevel = data.operating_level
    ? data.operating_level
    : undefined;

  const handleRefresh = React.useCallback(() => {
    (async () => {
      await API.controlDevice({
        uuid,
        control: 'mode',
        value: {init: true},
      });
    })();
  }, [uuid]);

  if (!data) {
    return null;
  }

  return (
    <Container>
      <div className="general-information">
        <div className="row">
          {waterLevel ? (
            <div className="col-md-6 mb-4">
              <WaterLevelGauage
                waterLevel={waterLevel}
                shutdownLevel={shutdownLevel}
                operatingLevel={operatingLevel}
              />
            </div>
          ) : (
            ''
          )}
          <div className={`col-md-${waterLevel ? 6 : 12} mb-4`}>
            <Card className="h-100">
              <CardHeader>{t('general_information')}</CardHeader>
              <CardBody>
                <Table className="m-0" borderless size="md">
                  <tbody>
                    <tr>
                      <td>{t('device_connection_status')}</td>
                      <td>
                        {connected
                          ? t('device_connection_status_connected')
                          : t('device_connection_status_disconnected')}
                      </td>
                    </tr>
                    <tr>
                      <td>{t('device_last_connected_at')}</td>
                      <td>
                        {lastConnectedAt
                          ? moment(lastConnectedAt).format('DD MMM YYYY HH:mm')
                          : t('device_not_connected')}
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">{t('st-engine_refresh')}</td>
                      <td className="align-middle">
                        <Button color="primary" onClick={handleRefresh}>
                          <Icon
                            className="align-middle mr-2"
                            name="RefreshCw"
                            size="x-small"
                          />
                          <span className="align-middle">{t('update')}</span>
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </div>
        <Card>
          <CardHeader>{t('st-engine')}</CardHeader>
          <CardBody>
            <Row>
              <Col md={12} lg={6}>
                <Row className="mb-5">
                  <Col md={6} className="text-center">
                    <CanvasGauge
                      value={
                        !connected
                          ? 0
                          : data.spd > 2500
                          ? data.spd > 10000
                            ? 0
                            : 2500
                          : data.spd
                      }
                      minValue={0}
                      maxValue={2500}
                      width={300}
                      height={300}
                      majorTicks={[0, 500, 1000, 1500, 2000, 2500]}
                    />
                    <p>{t('value_rpm')} (RPM)</p>
                  </Col>
                  <Col md={6} className="text-center">
                    <CanvasGauge
                      value={
                        !connected
                          ? 0
                          : data.oilp > 1000
                          ? data.oilp > 10000
                            ? 0
                            : 1000
                          : data.oilp
                      }
                      minValue={0}
                      maxValue={1000}
                      width={300}
                      height={300}
                      majorTicks={[0, 200, 400, 600, 800, 1000]}
                    />
                    <p>{t('st-engine_oilp')} (kPa)</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="text-center">
                    <CanvasGauge
                      linear
                      value={
                        !connected
                          ? 0
                          : data.fuel > 100
                          ? data.fuel > 1000
                            ? 0
                            : 100
                          : data.fuel
                      }
                      minValue={0}
                      maxValue={100}
                      width={150}
                      height={300}
                      majorTicks={[0, 20, 40, 60, 80, 100]}
                    />
                    <p className="mt-3">{t('value_fuel')} (%)</p>
                  </Col>
                  <Col md={4} className="text-center">
                    <CanvasGauge
                      linear
                      value={
                        !connected
                          ? 0
                          : data.cool > 200
                          ? data.cool > 1000
                            ? 0
                            : 200
                          : data.cool
                      }
                      minValue={0}
                      maxValue={200}
                      width={150}
                      height={300}
                      majorTicks={[0, 50, 100, 150, 200]}
                      highlights={null}
                    />
                    <p className="mt-3">{t('value_temperature')} (°C)</p>
                  </Col>
                  <Col md={4} className="text-center">
                    <CanvasGauge
                      linear
                      value={
                        !connected
                          ? 0
                          : data.batv > 35
                          ? data.batv > 100
                            ? 0
                            : 35
                          : data.batv
                      }
                      minValue={0}
                      maxValue={35}
                      width={150}
                      height={300}
                      valueInt={2}
                      majorTicks={[0, 5, 10, 15, 20, 25, 30, 35]}
                      highlights={null}
                    />
                    <p className="mt-3">{t('st-engine_battery')} (V.)</p>
                  </Col>
                </Row>
              </Col>
              <Col md={12} lg={6}>
                <STEngineControlPanel
                  disabled={!connected}
                  device={props.device}
                  data={data}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

export default STEngine;

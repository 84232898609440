import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Icon, ListTitle, ListSubtitle} from '../../common';
import {ListItem, ListItemIcon, ListItemContent} from './HomeStationsStyles';

interface HomeStationsListItemProps {
  station: any;
  onHover: (station: any, hover: boolean) => void;
}

export const HomeStationsListItem: React.FC<HomeStationsListItemProps> = (
  props: HomeStationsListItemProps
) => {
  const {station, onHover} = props;
  const history = useHistory();
  const {t} = useTranslation();

  const handleMouseEnter = React.useCallback(() => {
    onHover(station, true);
  }, [station]);

  const handleMouseLeave = React.useCallback(() => {
    onHover(station, false);
  }, [station]);

  const handleClick = React.useCallback(() => {
    history.push(`/stations/${station.id}`);
  }, [station.id]);

  return (
    <ListItem
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ListItemContent>
        <ListTitle>{station.name}</ListTitle>
        <ListSubtitle style={{marginTop: 12}}>
          {t('station_staff_name')} {station.staffName}
        </ListSubtitle>
        <ListSubtitle style={{marginTop: 4}}>
          {t('station_staff_tel')} {station.staffTel}
        </ListSubtitle>
      </ListItemContent>
      <ListItemIcon pointer>
        <Icon size="medium" name="ChevronRight" />
      </ListItemIcon>
    </ListItem>
  );
};

export default HomeStationsListItem;

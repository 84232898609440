import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useAsync} from 'react-use';
import {useRouteMatch} from 'react-router-dom';

import * as API from '../../api';
import {ContentContainer, Loading, TitleBar} from '../../common';
import {UserForm} from '../UserForm';

export const UserEditPage: React.FC<{}> = () => {
  const match = useRouteMatch();
  const {t} = useTranslation();

  const {id} = match.params;

  const state = useAsync(async () => {
    const res = await API.getOrganizationUser(id);
    return res.data;
  }, [id]);

  const handleSubmit = () => {};

  if (state.loading) {
    return <Loading />;
  }

  const value = state.value;

  const title = t('general_edit_title', {
    title: `${value.firstName} ${value.lastName}`,
  });

  return (
    <ContentContainer>
      <TitleBar title={title} />
      <hr />
      <UserForm values={value} onSubmit={handleSubmit} />
    </ContentContainer>
  );
};

export default UserEditPage;

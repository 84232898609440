import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect, DispatchProp } from 'react-redux';

import { Col, Row, Card, CardTitle, CardText } from 'reactstrap';

import { Icon, Map } from '../common';
import { getStations } from './modules';
import StationCard from './components/StationCard';

interface StationIndexProps extends DispatchProp, RouteComponentProps {
  loading: boolean;
  view: any;
}

const StationIndex: React.FunctionComponent<StationIndexProps> = (
  props: StationIndexProps
) => {
  React.useEffect(() => {
    props.dispatch(getStations(null, 'stationIndex'));
  }, []);

  const handleClick = React.useCallback(() => {
    props.history.push('/stations/new');
  }, []);

  const handleStationClick = React.useCallback((station: any) => {
    props.history.push(`/stations/${station.id}`);
  }, []);

  const { data } = props.view;

  const markers = data.map((station: any) => {
    return { lat: station.latitude, long: station.longitude };
  });

  return (
    <div className="station-list">
      <h2 className="d-flex align-items-center my-4">
        <Icon className="mr-2" name="MapPin" />
        <span>เลือกสถานี</span>
      </h2>
      <Row>
        <Col md={7} xl={6}>
          <Row>
            {props.view.data.map((station: any) => (
              <Col key={station.id} xs={6} lg={4} xl={3}>
                <StationCard station={station} onClick={handleStationClick} />
              </Col>
            ))}
            <Col xs={6} lg={4} xl={3}>
              <Card
                body
                role="menuItem"
                tabIndex={0}
                className="pointer text-center"
                onClick={handleClick}
              >
                <CardTitle>
                  <Icon size="medium" className="mb-2" name="Plus" />
                </CardTitle>
                <CardText>เพิ่มสถานี</CardText>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col md={5} xl={6}>
          <Map markers={markers} />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  view: state.station.stationIndex
});

export default connect(mapStateToProps)(StationIndex);

import * as React from 'react';

interface WaterLevelGauageProps {
  waterLevel: number;
  noLoadLevel: number;
  controlLevels: any[];
}

export const WaterLevelGauage: React.FC<WaterLevelGauageProps> = (
  props: WaterLevelGauageProps
) => {
  const {waterLevel, noLoadLevel, controlLevels} = props;

  return (
    <div className="water-level h-100">
      <div className="water-level-inner">
        <div className="water-level-bg" />
        <div className="water-level-bar" style={{height: `${waterLevel}%`}}>
          <div className="water-level-number">{waterLevel}%</div>
        </div>
        <div className="water-level-indicator">
          <div className="no-load" style={{top: `${100 - noLoadLevel}%`}}>
            {noLoadLevel}%
          </div>
          {controlLevels.map((level: number) => (
            <div key={level} style={{top: `${100 - level}%`}}>
              {level}%
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WaterLevelGauage;

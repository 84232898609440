import styled from 'styled-components';

import {themeConstants as c} from '../theme';

export const ContentContainer = styled.div`
`;

export const Panel = styled.div`
  padding: ${c.contentPadding};
`;

export const ListTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height 24px;
  max-height: 24px;
`;

export const ListSubtitle = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: #717171;
`;

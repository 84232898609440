import * as React from 'react';

import * as API from '../../api';

export const useDevice = (uuid: string) => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<any>(null);
  const [device, setDevice] = React.useState<any>(null);

  React.useEffect(() => {
    if (!uuid.length) {
      setError(new Error('Invalid uuid'));
      return;
    }

    (async () => {
      setLoading(true);

      try {
        const {data} = await API.getDevice(uuid);
        setDevice(data);
      } catch (e) {
        setError(e);
      }

      setLoading(false);
    })();
  }, [uuid]);

  return {device, error, loading, setDevice};
};

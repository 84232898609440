import * as React from 'react';
import {ThemeProvider} from 'styled-components';

import {defaultTheme} from './defaultTheme';

interface ThemeProps {
  name: string;
  children: any;
}

export const Theme: React.FC<ThemeProps> = (props: ThemeProps) => {
  return (
    <ThemeProvider theme={defaultTheme}>{props.children}</ThemeProvider>
  );
};

export default Theme;

import produce from 'immer';
import { types } from './types';

interface State {
  authenticated: boolean;
  hasToken: boolean;
  loggingIn: boolean;
}

const token = window.localStorage.getItem('token');
const hasToken = token !== null && token.length > 0;

const initialState: State = {
  authenticated: false,
  loggingIn: hasToken,
  hasToken
};

export const reducer = (state = initialState, action: any) =>
  produce(state, (draft: State) => {
    switch (action.type) {
      case types.LOGIN_REQUEST:
        draft.loggingIn = true;
        break;
      case types.LOGIN_FAILURE:
        draft.loggingIn = false;
        break;
      case types.LOGIN_SUCCESS:
        draft.loggingIn = false;
        break;
      case types.CHECK_ME_REQUEST:
        draft.loggingIn = true;
        break;
      case types.CHECK_ME_SUCCESS:
        draft.authenticated = true;
        draft.loggingIn = false;
        break;
      case types.CHECK_ME_FAILURE:
        draft.authenticated = false;
        draft.loggingIn = false;
        draft.hasToken = false;
        break;
    }
  });

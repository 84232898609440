import * as React from 'react';

import { Table } from 'reactstrap';

interface DeviceTableProps {
  devices: any[];
}

const DeviceTable: React.FunctionComponent<DeviceTableProps> = (
  props: DeviceTableProps
) => {
  return (
    <div className="device-table">
      <Table>
        <thead>
          <tr>
            <td>ID</td>
            <td>UUID</td>
            <td>Status</td>
            <td>Last Connected At</td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>
          {props.devices.map((device: any) => (
            <tr key={device.id}>
              <td>{device.id}</td>
              <td>{device.uuid}</td>
              <td>{device.status}</td>
              <td>{device.lastConnectedAt}</td>
              <td>Some Icon</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default DeviceTable;

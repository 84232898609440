import * as React from 'react';
import { LinearGauge, RadialGauge } from 'react-canvas-gauges';

interface GaugeProps {
  className?: string;
  linear?: boolean;
  height?: number;
  min?: number;
  max?: number;
  title?: string;
  int?: number;
  decimal?: number;
  value: number;
  highlights?: any;
  ticks?: string[];
  minorTicks?: number;
  startAngle?: number;
  ticksAngle?: number;
}

const Gauge: React.SFC<GaugeProps> = (props: GaugeProps) => {
  if (props.linear) {
    return (
      <div className={`gauge-container-linear ${props.className}`}>
        <LinearGauge
          value={props.value}
          minValue={props.min}
          maxValue={props.max}
          borderShadowWidth={0}
          barWidth={12}
          height={props.height}
          majorTicks={props.ticks}
          minorTicks={props.minorTicks}
          borders={false}
          highlights={props.highlights}
          barStrokeWidth={0}
          valueBoxStroke={0}
          tickSide="left"
          numberSide="left"
          needleSide="left"
          animationRule="bounce"
          barBeginCircle={false}
          animationDuration={750}
        />
        <div className="gauge-title font-weight-bold text-center">
          {props.title} :
          <span className="gauge-value ml-2">{props.value}</span>
        </div>
      </div>
    )
  }

  return (
    <div className={`gauge-container ${props.className}`}>
      <RadialGauge
        title={props.title}
        minValue={props.min}
        startAngle={props.startAngle}
        ticksAngle={props.ticksAngle}
        value={props.value}
        maxValue={props.max}
        majorTicks={props.ticks}
        minorTicks={props.minorTicks}
        strokeTicks
        highlights={props.highlights}
        colorPlate="#fff"
        borderShadowWidth={0}
        borders={false}
        needleType="arrow"
        needleCircleOuter
        needleCircleInner={false}
        animationDuration={1500}
        animationRule="linear"
        colorValueBoxBackground="#ddd"
        valueInt={props.int}
        valueDec={props.decimal}
      />
    </div>
  );
};

Gauge.defaultProps = {
  className: '',
  linear: false,
  height: 300,
  min: 0,
  max: 100,
  title: '',
  highlights: [
    {
      from: 250,
      to: 300,
      color: "rgba(200, 50, 50, 0.75)"
    }
  ],
  ticks: ['20', '40', '60', '80', '100'],
  startAngle: 45,
  ticksAngle: 270,
  int: 3,
  decimal: 0,
};

export default Gauge;

import React from 'react';

import { Card, Spinner } from 'reactstrap';

interface LoadingProps {
  card?: boolean;
  label?: string;
}

const Loading: React.FunctionComponent<LoadingProps> = (
  props: LoadingProps
) => {

  if (props.card) {
    return (
      <Card body>
        <Spinner
          color="primary"
          style={{ width: '4rem', height: '4rem', margin: 'auto' }}
        />{' '}
      </Card>
    );
  }

  return (
    <React.Fragment>
      <Spinner
        color="primary"
        style={{ width: '4rem', height: '4rem' }}
      />{' '}
    </React.Fragment>
  );
};

Loading.defaultProps = {
  card: false,
  label: 'กำลังโหลด...'
};

export default Loading;

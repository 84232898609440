import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {Button, Col, FormGroup, Label, Input} from 'reactstrap';

interface DeviceEditFormProps {
  values?: any;
  onSubmit: (values: any) => void;
}

export const DeviceEditForm: React.FC<DeviceEditFormProps> = (
  props: DeviceEditFormProps
) => {
  const {values, onSubmit} = props;

  const {t} = useTranslation();

  const submitFn = React.useCallback(
    (values: any) => {
      onSubmit(values);
    },
    [onSubmit]
  );

  const {register, handleSubmit} = useForm({
    defaultValues: values,
  });

  return (
    <form onSubmit={handleSubmit(submitFn)} className="p-3 mb-3 border rounded">
      <FormGroup row>
        <Label for="name" sm={3} className="font-weight-bold">
          {t('device_label')}
        </Label>
        <Col sm={9}>
          <Input innerRef={register} type="text" name="label" />
        </Col>
      </FormGroup>
      <Button type="submit" color="primary">
        {t('save')}
      </Button>
    </form>
  );
};

export default DeviceEditForm;

import * as React from 'react';

import {DashboardFilter} from './DashboardFilter';

import {
  Container,
  LeftPanel,
  RightPanel,
} from './DashboardStyles';

export const Dashboard: React.FC<{}> = () => {
  return (
    <Container>
      <DashboardFilter>
      </DashboardFilter>
        <LeftPanel>
          Left Panel
        </LeftPanel>
        <RightPanel>
          Right Panel
        </RightPanel>
    </Container>
  );
};

export default Dashboard;

import axios from 'axios';
import humps from 'humps';

export function getOrganizationStations({page}: any) {
  return axios({
    method: 'GET',
    url: '/organization/stations',
    params: {page},
  });
}

export function getOrganizationStationStaffs(id: string | number) {
  return axios({
    method: 'GET',
    url: `/organization/stations/${id}/staffs`,
  });
}

export function updateStationStaffs(id: string | number, staffIds: any[]) {
  const data = humps.decamelizeKeys({staffIds});

  return axios({
    method: 'PUT',
    url: `/organization/stations/${id}/staffs`,
    data,
  });
}

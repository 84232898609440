import axios from 'axios';

function getStation({ id }: any) {
  return axios({
    method: 'GET',
    url: `/stations/${id}`
  });
}

function getStations() {
  return axios({
    method: 'GET',
    url: '/stations'
  });
}

function getStationActivities({ id, from, to, page }: any) {
  const params: any = {};

  if (from && to) {
    params.from = from;
    params.to = to;
  }

  if (page) {
    params.page = page;
  }

  return axios({
    method: 'GET',
    url: `/stations/${id}/activities`,
    params
  });
}

function createStation(data: any) {
  return axios({
    method: 'POST',
    url: '/stations',
    data
  });
}

function updateStation(data: any) {
  return axios({
    method: 'PUT',
    url: `/stations/${data.id}`,
    data
  });
}

export const api = {
  getStation,
  getStationActivities,
  getStations,
  createStation,
  updateStation
};

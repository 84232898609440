import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Col, Row} from 'reactstrap';
import numeral from 'numeral';
import moment from 'moment';

import {DeviceDataBox} from '../DeviceDataBox';

interface STNahmdeePreviewProps {
  device: any;
}

export const STNahmdeePreview: React.FC<STNahmdeePreviewProps> = (
  props: STNahmdeePreviewProps
) => {
  const {device} = props;
  const {state} = device;

  const {t} = useTranslation();

  return (
    <Row>
      <Col md={3}>
        <DeviceDataBox icon="wifi" title={device.uuid}>
          <p>
            <b>{t('DO')}</b>:&nbsp;{numeral(state.do).format('0.00')}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <b>{t('Temperature')}</b>:&nbsp;
            {numeral(state.temperature).format('0.00')}
            <br />
            <b>{t('Last Conntected')}</b>:&nbsp;
            {moment(device.lastConnectedAt).format('YYYY/MM/DD - HH:mm')}
          </p>
        </DeviceDataBox>
      </Col>
    </Row>
  );
};

export default STNahmdeePreview;

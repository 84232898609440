import * as React from 'react';
import {useTranslation} from 'react-i18next';

import {Card, CardBody, CardHeader} from 'reactstrap';

import * as API from '../../api';

interface ControlPanelProps {
  uuid: string;
  data: any;
}

export const ControlPanel: React.FC<ControlPanelProps> = (
  props: ControlPanelProps
) => {
  const {data, uuid} = props;
  const {t} = useTranslation();

  const handleAction = React.useCallback(
    (value: string) => {
      (async () => {
        await API.controlDevice({
          uuid: uuid,
          control: value,
          value: true,
        });
      })();
    },
    [uuid]
  );

  return (
    <Card className="mb-4">
      <CardHeader>
        <span className="font-weight-bold">{t('gate_control_panel')}</span>
      </CardHeader>
      <CardBody>
        <div className="row">
          <div className="col-4 mb-3 text-center">
            <button
              type="button"
              className="action-button"
              disabled={data.gateOpen}
              onClick={() => handleAction('gateManualOpen')}
            >
              <div className="success">
                <span>{t('open')}</span>
              </div>
            </button>
            <span>
              <span
                className={`mr-3 led led-${data.gateOpen ? 'success' : 'idle'}`}
              />
              {t('st-waterpump_gate_open')}
            </span>
          </div>
          <div className="col-4 text-center">
            <button
              type="button"
              className="action-button"
              onClick={() => handleAction('gateManualStop')}
            >
              <div className="secondary">
                <span>{t('stop')}</span>
              </div>
            </button>
            <span>
              <span
                className={`mr-3 led led-${
                  !data.gateOpen && !data.gateClose ? 'success' : 'idle'
                }`}
              />
              {t('st-waterpump_gate_stop')}
            </span>
          </div>
          <div className="col-4 text-center">
            <button
              type="button"
              className="action-button"
              disabled={data.gateClose}
              onClick={() => handleAction('gateManualClose')}
            >
              <div className="fail">
                <span>{t('close')}</span>
              </div>
            </button>
            <span>
              <span
                className={`mr-3 led led-${
                  data.gateClose ? 'success' : 'idle'
                }`}
              />
              {t('st-waterpump_gate_close')}
            </span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ControlPanel;

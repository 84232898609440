import * as React from 'react';

import Loading from './Loading';

interface LoadableProps {
  loading: boolean;
  className?: string;
  children: React.ReactNode | (() => React.ReactNode);
}

export const Loadable: React.FunctionComponent<LoadableProps> = (
  props: LoadableProps
) => {
  if (props.loading) {
    return (
      <div className={`${props.className} table-middle text-center`}>
        <div className="inner">
          <Loading />
        </div>
      </div>
    );
  }

  let content;

  if (typeof props.children === 'function') {
    content = props.children();
  } else {
    content = props.children;
  }

  return content;
};

Loadable.defaultProps = {
  className: 'loading',
};

export default Loadable;

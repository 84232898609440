import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import th_TH from '../../assets/translations/th_TH.json';
import en_US from '../../assets/translations/en_US.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {en_US, th_TH};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'th_TH',
    fallbackLng: 'en_US',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

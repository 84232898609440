import * as React from 'react';
import {useRouteMatch} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useAsync, useAsyncFn} from 'react-use';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';

import * as API from '../../api';
import {ContentContainer, Icon, Loading, TitleBar} from '../../common';

import {UserInput} from '../UserInput';

export const StationStaffPage: React.FC<{}> = () => {
  const {t} = useTranslation();
  const match = useRouteMatch();

  const {id} = match.params;
  const [staffs, setStaffs] = React.useState<any[]>([]);

  const state = useAsync(async () => {
    const {data} = await API.getStation({id});
    const {data: staffs} = await API.getOrganizationStationStaffs(id);

    setStaffs(staffs);

    return {station: data.station, staffs};
  }, [id]);

  const handleAddStaff = React.useCallback(
    (data: any) => {
      if (staffs.indexOf(data.id) === -1) {
        setStaffs([...staffs, data]);
      }
    },
    [staffs]
  );

  const handleDeleteStaff = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const {id} = e.currentTarget;
      const comps = id.match(/(\S+)-(\S+)/);

      if (comps) {
        const sid = parseInt(comps[2], 10);
        const next = staffs.filter((s: any) => s.id !== sid);

        setStaffs(next);
      }
    },
    [staffs]
  );

  const [staffState, submit] = useAsyncFn(async () => {
    const staffIds = staffs.map((s: any) => s.id);
    const res = await API.updateStationStaffs(id, staffIds);

    return res.data;
  }, [staffs]);

  if (state.loading || staffState.loading) {
    return <Loading />;
  }

  const {station} = state.value || {};
  const staffIds = staffs.map((s: any) => s.id);

  return (
    <ContentContainer>
      <TitleBar title={`${station.name} Staffs`} />
      <Card>
        <CardHeader>
          <div className="d-flex align-items-center">
            <span className="font-weight-bold">{t('station_staffs')}</span>
          </div>
        </CardHeader>
        <CardBody>
          <ListGroup className="mb-3">
            {staffs.map((s: any) => (
              <ListGroupItem key={s.id}>
                <span>
                  {s.firstName} {s.lastName} ({s.email})
                </span>
                <Button
                  id={`delete-${s.id}`}
                  color="text-muted"
                  className="ml-auto p-0"
                  onClick={handleDeleteStaff}
                >
                  <Icon name="Trash" />
                </Button>
              </ListGroupItem>
            ))}
          </ListGroup>
          <UserInput
            onSelect={handleAddStaff}
            filterOption={(opt: any) => staffIds.indexOf(opt.data.id) === -1}
          />
          <br />
          <div className="text-right">
            <Button type="button" color="primary" onClick={submit}>
              {t('general_save')}
            </Button>
          </div>
        </CardBody>
      </Card>
    </ContentContainer>
  );
};

export default StationStaffPage;

import * as React from 'react';
import isNumber from 'lodash/isNumber';
import {Card, CardBody, CardHeader} from 'reactstrap';

interface MeterWidgetProps {
  value: any[];
  size: number;
  label: string;
  rounding?: boolean;
}

export const MeterWidget: React.FC<MeterWidgetProps> = (
  props: MeterWidgetProps
) => {
  const {label, value, rounding, size} = props;

  return (
    <Card className="mb-3">
      <CardHeader className="transparent h6 font-weight-bold">
        {label}
      </CardHeader>
      <CardBody className="text-right pt-0">
        {value.map((v: any) => (
          <p className={`h${size} m-0`} key={v.unit}>
            {rounding && isNumber(v.value) ? Math.round(v.value) : v.value}
            &nbsp;{v.unit}
          </p>
        ))}
      </CardBody>
    </Card>
  );
};

export default MeterWidget;

import * as React from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {Button} from 'reactstrap';

import Icon from './Icon';

interface TitleBarProps {
  title: string;
  backButton?: boolean;
  children?: any;
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;

  > h2 {
    margin-bottom: 0px;
  }
`;

const RightContent = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleBar: React.FC<TitleBarProps> = (props: TitleBarProps) => {
  const {backButton, children, title} = props;
  const history = useHistory();

  return (
    <Container className="py-3 px-3 mb-4 border-bottom">
      {backButton !== false && (
        <Button
          type="button"
          color="text-muted"
          className="p-0 mr-3"
          onClick={history.goBack}
        >
          <Icon name="ChevronLeft" />
        </Button>
      )}
      <Title>
        <h2>{title}</h2>
      </Title>
      {children && <RightContent>{children}</RightContent>}
    </Container>
  );
};

export default TitleBar;

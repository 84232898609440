import React from 'react';
import {Row, Col, Card, CardBody} from 'reactstrap';
import {Icon} from '../../common';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

interface STWaterPumpPreviewProps {
  device: any;
}

export const STWaterPumpPreview: React.FC<STWaterPumpPreviewProps> = (
  props: STWaterPumpPreviewProps
) => {
  const {t} = useTranslation();
  const {device} = props;
  const {lastConnectedAt, state} = device;

  const checkError = React.useCallback((state: any) => {
    if (
      state.moisture ||
      state.overload ||
      state.phaseProt ||
      state.waterLeak
    ) {
      return true;
    } else {
      return false;
    }
  }, []);
  if (
    state.pumps &&
    Object.keys(state.pumps).length &&
    Object.keys(state.pumps).length === state.noOfPumps
  ) {
    const pumpKeys = Object.keys(state.pumps);
    const connected = moment().diff(moment(lastConnectedAt), 'minutes') < 5;

    return (
      <Row>
        {pumpKeys.map((i: string) => (
          <Col xs={12} md={4} key={i} className="py-2">
            <Card>
              <CardBody className="p-0 d-flex align-items-center">
                {checkError(state.pumps[i]) ? (
                  <div className="p-4 mr-3 text-center bg-danger text-white">
                    <Icon name="AlertTriangle" />
                  </div>
                ) : (
                  <React.Fragment>
                    {connected && state.pumps[i].online ? (
                      <div className="p-4 mr-3 text-center bg-primary text-white">
                        <Icon name="Wifi" />
                      </div>
                    ) : (
                      <div className="p-4 mr-3 text-center bg-secondary text-white">
                        <Icon name="WifiOff" />
                      </div>
                    )}
                  </React.Fragment>
                )}
                <div className="text-xs">
                  <div className="pump-preview-power mb-0">
                    <Icon name="Zap" className="mr-2" />
                    {`${t('st-waterpump_pump_size')} ${state.pumps[i].power}kW`}
                  </div>
                  <div className="pump-preview-order mb-0">
                    <Icon name="Shuffle" className="mr-2" />
                    {`${t('st-waterpump_pump_order')} ${state.pumps[i].order}`}
                  </div>
                  {connected && state.pumps[i].on ? (
                    <div className="pump-preview-status">
                      <span className="led led-success mr-2" />
                      <span>{t('st-waterpump_pump_on')}</span>
                    </div>
                  ) : (
                    <div className="pump-preview-status">
                      <span className="led led-idle mr-2" />
                      <span>{t('st-waterpump_pump_off')}</span>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    );
  } else {
    return <h3 className="m-0">There is no pump found</h3>;
  }
};

export default STWaterPumpPreview;

import * as React from 'react';
import { Card, CardTitle, CardText } from 'reactstrap';
import { Icon } from '../../common';

interface StationCardProps {
  station: any;
  onClick: (station: any) => void;
}

const StationCard: React.FunctionComponent<StationCardProps> = (
  props: StationCardProps
) => {
  const handleClick = React.useCallback(() => {
    props.onClick(props.station);
  }, []);

  return (
    <Card
      body
      inverse
      color="primary"
      role="menuItem"
      tabIndex={0}
      className="pointer text-center mb-3"
      onClick={handleClick}
    >
      <CardTitle>
        <Icon size="medium" className="mb-2" name="Wifi" />
      </CardTitle>
      <CardText>{props.station.name}</CardText>
    </Card>
  );
};

export default StationCard;

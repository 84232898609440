import * as React from 'react';
import {hot} from 'react-hot-loader/root';
import {Switch, Redirect} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';

import {Loadable, MainContent} from './common';
import {hasRole} from './util';

import {Theme} from './theme';
import {ErrorBoundary, Header} from './main';
import {routes as authRoutes, AuthContext} from './auth';
import {routes as homeRoutes} from './home';
import {routes as deviceRoutes} from './device';
import {routes as stationRoutes} from './station';
import {routes as userRoutes} from './user';
import {Routes as orgRoutes} from './organization';
import {AuthState} from './auth/AuthContext/modules';

const App: React.FunctionComponent<{}> = (): JSX.Element => {
  const state = React.useContext<AuthState>(AuthContext);
  const {authenticated, loading, user, authToken} = state;

  const hasAuthToken = authToken && authToken.length !== 0;

  return (
    <Theme name="default">
      <Loadable loading={loading}>
        <ErrorBoundary>
          {authenticated ? (
            <React.Fragment>
              <Header />
              <MainContent>
                <Switch>
                  {deviceRoutes}
                  {stationRoutes}
                  {userRoutes}
                  {homeRoutes}
                  {hasRole(user.roles, 'ROLE_ORG_ADMIN') && orgRoutes}
                  <Redirect to="/home/stations" />
                </Switch>
              </MainContent>
            </React.Fragment>
          ) : (
            <Switch>
              {authRoutes}
              {!hasAuthToken && <Redirect to="/login" />}
            </Switch>
          )}
        </ErrorBoundary>
      </Loadable>
      <ToastContainer
        toastClassName="text-center"
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
      />
    </Theme>
  );
};

export default hot(App);

import * as React from 'react';
import {Route} from 'react-router-dom';

import DeviceList from './DeviceList';
import {DeviceDetail} from './DeviceDetail';
import {DeviceEdit} from './DeviceEdit';

export default [
  <Route
    key="device-edit"
    exact
    path="/devices/:uuid/edit"
    component={DeviceEdit}
  />,
  <Route key="device" exact path="/devices/:uuid" component={DeviceDetail} />,
  <Route key="device-list" exact path="/devices" component={DeviceList} />,
];

import moment from 'moment';
import numeral from 'numeral';

export interface DateRange {
  from: Date;
  to: Date;
}

export function printNumber(value: any) {
  return numeral(value).format('0.00');
}

export function printDate(date: Date | moment.Moment) {
  return moment(date).format('DD MMM YYYY');
}

export function printHourTime(i: any) {
  const mins = i * 30;
  const hrs = Math.floor(mins / 60);

  return `${hrs}:${i % 2 ? '30' : '00'}`;
}

export function isSameDay(dateRange: DateRange) {
  const from = moment(dateRange.from).startOf('day');
  const to = moment(dateRange.to).startOf('day');

  return from.isSame(to);
}

export function printDateRange(dateRange: DateRange) {
  if (isSameDay(dateRange)) {
    return printDate(dateRange.from);
  }

  return `${printDate(dateRange.from)} - ${printDate(dateRange.to)}`;
}

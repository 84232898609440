import { types } from './types';

export function getStations(payload: any, sender: string) {
  return { type: types.GET_STATIONS_REQUEST, sender, payload };
}

export function createStation(payload: any, sender: string) {
  return { type: types.CREATE_STATION_REQUEST, sender, payload };
}

export function getStation(payload: any, sender: string) {
  return { type: types.GET_STATION_REQUEST, sender, payload };
}

export function getStationActivities(payload: any, sender: string) {
  return { type: types.GET_STATION_ACTIVITIES_REQUEST, sender, payload };
}

export function updateStation(payload: any, sender: string) {
  return { type: types.UPDATE_STATION_REQUEST, payload, sender };
}

export function stationActivityInit() {
  return { type: types.STATION_ACTIVITY_INIT };
}

import * as React from 'react';
import { Button } from 'reactstrap';

export const UserIndex: React.FC<{}> = () => {
  const handleTest = React.useCallback(() => {
    console.log('handle test');
  }, []);

  return (
    <div className="user-index">
      User Index
      <Button onClick={handleTest}>Test Button</Button>
    </div>
  );
};

export default UserIndex;

import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';

import { getDevices } from './modules';

import DeviceTable from './components/DeviceTable';

interface DeviceListProps extends DispatchProp {
  view: any;
}

const DeviceList: React.FunctionComponent<DeviceListProps> = (
  props: DeviceListProps
) => {
  React.useEffect(() => {
    props.dispatch(getDevices());
  }, []);

  return (
    <div className="device-list">
      <h2>Device List</h2>
      <DeviceTable devices={props.view.data} />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  view: state.device.deviceList
});

export default connect(mapStateToProps)(DeviceList);

import * as React from 'react';
import {Card, CardBody} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import {Icon} from '../../common';
import moment from 'moment';

interface STEnginePreviewProps {
  device: any;
}

export const STEnginePreview: React.FC<STEnginePreviewProps> = (
  props: STEnginePreviewProps
) => {
  const {t} = useTranslation();
  const {device} = props;
  const {lastConnectedAt, state} = device;

  const checkError = React.useCallback((state: any) => {
    if (
      state.lwfu ||
      state.lwop ||
      state.ovspd ||
      state.hicool
    ) {
      return true;
    } else {
      return false;
    }
  }, []);

  const connected = moment().diff(moment(lastConnectedAt), 'minutes') < 5;

  return (
    <Card>
      <CardBody className="p-0 d-flex align-items-center">
        {checkError(state) ? (
          <div className="p-4 mr-3 text-center bg-danger text-white">
            <Icon name="AlertTriangle" />
          </div>
        ) : (
          <React.Fragment>
            {connected ? (
              <div className="p-4 mr-3 text-center bg-primary text-white">
                <Icon name="Wifi" />
              </div>
            ) : (
              <div className="p-4 mr-3 text-center bg-secondary text-white">
                <Icon name="WifiOff" />
              </div>
            )}
          </React.Fragment>
        )}
        <div className="text-xs">
          <div className="engine-preview-mode mb-0">
            <Icon name="Info" className="mr-2" />
            {`${t(`st-engine_mode`)} : ${t(`st-engine_mode_${state.mode}`)}`}
          </div>
          <div className="engine-preview-batv mb-0">
            <Icon name="Zap" className="mr-2" />
            {`${t(`st-engine_battery`)} : ${state.batv ? state.batv : '##'} V`}
          </div>
          <div className="engine-preview-fuel mb-0">
            <Icon name="Droplet" className="mr-2" />
            {`${t(`value_fuel`)} : ${state.fuel && state.fuel < 1000 ? state.fuel : '##'}%`}
          </div>
          <div className="engine-preview-hrs mb-0">
            <Icon name="Clock" className="mr-2" />
            {`${t(`st-engine_hrs`)} : ${state.hrs ? state.hrs : '##'} hrs`}
          </div>
        </div>
      </CardBody>
    </Card>
  )
};

export default STEnginePreview;

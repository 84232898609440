import * as React from 'react';

import {ErrorPage} from '../ErrorPage';

interface ErrorBoundaryProps {
  children: any;
}

interface ErrorBoundaryState {
  error: any;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error: any) {
    return {error: error};
  }

  render() {
    const {children} = this.props;
    const {error} = this.state;

    if (error) {
      return <ErrorPage error={error} />;
    }

    return children;
  }
}

import { takeEvery } from 'redux-saga/effects';

import { createAPISaga } from '../../util';
import { api } from './api';
import { types } from './types';

const getDevices = createAPISaga(api.getDevices);
const getDevice = createAPISaga(api.getDevice);
const controlDevice = createAPISaga(api.controlDevice);

export const sagas = [
  takeEvery(types.GET_DEVICES_REQUEST, getDevices),
  takeEvery(types.GET_DEVICE_REQUEST, getDevice),
  takeEvery(types.CONTROL_DEVICE_REQUEST, controlDevice)
];

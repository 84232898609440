import styled from 'styled-components';

export const SettingList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const SettingItem = styled.li`
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;

  &:hover {
    background-color: #ececec;
  }

  p {
    margin: 0;
  }
`;

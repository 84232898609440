import axios from 'axios';
import humps from 'humps';

export function getOrganizationUsers({page}: any) {
  return axios({
    method: 'GET',
    url: '/organization/users',
    params: {page},
  });
}

export function searchOrganizationUsers(q: string) {
  return axios({
    method: 'GET',
    url: '/organization/users/search',
    params: {q},
  });
}

export function createOrganizationUser(data: any) {
  return axios({
    method: 'POST',
    url: '/organization/users',
    data: humps.decamelizeKeys(data),
  });
}

export function getOrganizationUser(id: string | number) {
  return axios({
    method: 'GET',
    url: `/organization/users/${id}`,
  });
}

export function deleteOrganizationUser(id: string | number) {
  return axios({
    method: 'DELETE',
    url: `/organization/users/${id}`,
  });
}

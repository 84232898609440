import * as React from 'react';
import {useTranslation} from 'react-i18next';

import {Card, CardBody, CardHeader, Col, Row, Table} from 'reactstrap';

import WaterLevelGauage from './WaterLevelGauage';
import InfoPanel from './InfoPanel';
import ControlPanel from './ControlPanel';
import GateControlPanel from './GateControlPanel';
import MeterWidget from './MeterWidget';
import PumpControlPanel from './PumpControlPanel';

interface STWaterPumpProps {
  device: any;
  data: any;
}

function shouldIgnoreThermal(thermal: number) {
  return thermal < 0 || thermal > 1000;
}

export const STWaterPump: React.FC<STWaterPumpProps> = (
  props: STWaterPumpProps
) => {
  const {device} = props;

  const data = props.data || props.device.state;

  const {t} = useTranslation();

  let pumps: any = [];

  if (data && data.pumps) {
    pumps = Object.keys(data.pumps);
  }

  let controlLevels: any = [];

  if (data && data.controlLevels) {
    controlLevels = data.controlLevels;
  }

  const noLoadLevel = data.noLoadLevel > 100 ? 100 : data.noLoadLevel;
  const waterLevel = data.waterLevel > 100 ? 100 : data.waterLevel;

  return (
    <div className="container pt-5">
      <div className="row">
        <div className="col-md-3 mb-4">
          <WaterLevelGauage
            waterLevel={waterLevel}
            noLoadLevel={noLoadLevel}
            controlLevels={controlLevels}
          />
        </div>
        <div className="col-md-4 mb-4">
          <ControlPanel
            uuid={device.uuid}
            mode={data.autoMode ? 'auto' : 'manual'}
          />
        </div>
        <div className="col-md-5 mb-4">
          <InfoPanel data={data} device={device} />
        </div>
      </div>
      {data.gateOnline || data.gateOpen || data.gateClose ? (
        <GateControlPanel uuid={device.uuid} data={data} />
      ) : null}
      {pumps.length > 0 &&
        pumps.map((i: string) => (
          <Card key={i} className="mb-4">
            <CardHeader>
              <div className="d-flex align-items-center">
                <span className="font-weight-bold">
                  {t('st-waterpump_pump')} {data.pumps[i].power} kW
                </span>
                {data.pumps[i].online ? (
                  <span className="ml-3 text-xs text-muted">
                    <span className="led led-success mr-3" />
                    {t('st-waterpump_online')}
                  </span>
                ) : (
                  <span className="ml-3 text-xs text-muted">
                    <span className="led led-idle mr-3" />
                    {t('st-waterpump_offline')}
                  </span>
                )}
              </div>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={5}>
                  <PumpControlPanel pump={i} uuid={device.uuid} />
                  <Table className="mt-2 mb-0" borderless size="md">
                    <tbody>
                      <tr>
                        <td>{t('device_operating_status')}</td>
                        <td>
                          {data.pumps[i].on ? (
                            <React.Fragment>
                              <span className="led led-success mr-3" />
                              {t('st-waterpump_pump_on')}
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <span className="led led-idle mr-3" />
                              {t('st-waterpump_pump_off')}
                            </React.Fragment>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>{t('st-waterpump_pump_order')}</td>
                        <td>{data.pumps[i].order}</td>
                      </tr>
                      <tr>
                        <td>{t('st-waterpump_pump_hours')}</td>
                        <td>{data.pumps[i].hours.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col md={4}>
                  <MeterWidget
                    rounding
                    label={t('system')}
                    size={2}
                    value={[
                      {
                        unit: 'V',
                        value:
                          data.pumps[i].volt > 500 ? '###' : data.pumps[i].volt,
                      },
                      {
                        unit: 'A',
                        value:
                          data.pumps[i].amp > 400 ? '###' : data.pumps[i].amp,
                      },
                    ]}
                  />
                  <MeterWidget
                    rounding
                    label={t('thermal')}
                    size={2}
                    value={[
                      {
                        unit: '°C',
                        value:
                          data.pumps[i].thermal > 200
                            ? '###'
                            : data.pumps[i].thermal,
                      },
                    ]}
                  />
                  <MeterWidget
                    rounding
                    label={t('bearing_temp')}
                    size={2}
                    value={[
                      {
                        unit: '°C',
                        value:
                          data.pumps[i].bearingTemp > 200
                            ? '###'
                            : data.pumps[i].bearingTemp,
                      },
                    ]}
                  />
                </Col>
                <Col md={3}>
                  <Table className="mb-0" borderless size="md">
                    <tbody>
                      <tr>
                        <td>{t('st-waterpump_pump_overload')}</td>
                        <td>
                          {data.pumps[i].overload ? (
                            <span className="led led-danger mr-2" />
                          ) : (
                            <span className="led led-idle mr-2" />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>{t('st-waterpump_pump_phase_protection')}</td>
                        <td>
                          {data.pumps[i].phaseProt ? (
                            <span className="led led-danger mr-2" />
                          ) : (
                            <span className="led led-idle mr-2" />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>{t('st-waterpump_pump_earth_leak')}</td>
                        <td>
                          {data.pumps[i].earthLeak ? (
                            <span className="led led-danger mr-2" />
                          ) : (
                            <span className="led led-idle mr-2" />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>{t('st-waterpump_pump_water_leak')}</td>
                        <td>
                          {data.pumps[i].waterLeak ? (
                            <span className="led led-danger mr-2" />
                          ) : (
                            <span className="led led-idle mr-2" />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>{t('st-waterpump_pump_moisture_detect')}</td>
                        <td>
                          {data.pumps[i].moisture ? (
                            <span className="led led-danger mr-2" />
                          ) : (
                            <span className="led led-idle mr-2" />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>{t('st-waterpump_pump_critical_thermal')}</td>
                        <td>
                          {!shouldIgnoreThermal(data.pumps[i].thermal) &&
                          data.pumps[i].thermal >
                            data.pumps[i].criticalThermal ? (
                            <span className="led led-danger mr-2" />
                          ) : (
                            <span className="led led-idle mr-2" />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>{t('st-waterpump_pump_critical_bearing')}</td>
                        <td>
                          {!shouldIgnoreThermal(data.pumps[i].bearingTemp) &&
                          data.pumps[i].bearingTemp >
                            data.pumps[i].criticalBearTemp ? (
                            <span className="led led-danger mr-2" />
                          ) : (
                            <span className="led led-idle mr-2" />
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
        ))}
    </div>
  );
};

export default STWaterPump;

import * as React from 'react';
import {useAsyncFn} from 'react-use';
import {useTranslation} from 'react-i18next';
import {useRouteMatch} from 'react-router-dom';

import * as API from '../../api';
import {ContentContainer, TitleBar, Loading} from '../../common';
import {useDevice} from '../modules';

import {DeviceEditForm} from './DeviceEditForm';

export const DeviceEdit: React.FC<{}> = () => {
  const match = useRouteMatch();
  const {t} = useTranslation();

  const {uuid} = match.params;
  const {device, loading, error, setDevice} = useDevice(uuid);

  const [state, submitFn] = useAsyncFn<any>(
    async (...args: any[]) => {
      const values = args[0];
      const {data} = await API.updateDevice(uuid, values);
      setDevice(data);
      return data;
    },
    [uuid]
  );

  const handleSubmit = React.useCallback(
    (values: any) => {
      submitFn(values);
    },
    [uuid, submitFn]
  );

  if (loading || state.loading) {
    return <Loading />;
  }

  if (error || state.error) {
    return null;
  }

  const {label} = device;

  return (
    <ContentContainer>
      <TitleBar title={t('device_edit_title', {label})} />
      <DeviceEditForm values={device} onSubmit={handleSubmit} />
    </ContentContainer>
  );
};

export default DeviceEdit;

import {delay, takeEvery} from 'redux-saga/effects';
import {Action, createAPISaga} from '../../util';

import {types} from './types';
import * as api from './api';

const login = createAPISaga(api.login);

function* loginSuccess(action: Action) {
  const {token} = action.payload.data;
  yield window.localStorage.setItem('authToken', token);

  setTimeout(() => {
    window.location.href = '/stations';
  }, 1000);
}

function* logout() {
  yield window.localStorage.removeItem('token');
  yield window.localStorage.removeItem('authToken');
  yield delay(1000);

  window.location.href = '/login';
}

const checkMe = createAPISaga(api.checkMe);

export const sagas = [
  takeEvery(types.LOGIN_REQUEST, login),
  takeEvery(types.LOGIN_SUCCESS, loginSuccess),
  takeEvery(types.CHECK_ME_REQUEST, checkMe),
  takeEvery(types.LOGOUT_REQUEST, logout),
];

import * as React from 'react';
import {useTranslation} from 'react-i18next';
import DayPicker from 'react-day-picker';
import moment from 'moment';

import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Popover,
  PopoverBody,
} from 'reactstrap';

import * as API from '../../api';
import {printDate} from './helper';

interface STNahmdeeDownloadModalProps {
  isOpen: boolean;
  toggle: () => void;
}

const now = moment();

export const STNahmdeeDownloadModal: React.FC<STNahmdeeDownloadModalProps> = (
  props: STNahmdeeDownloadModalProps
) => {
  const {t} = useTranslation();

  const [loading, setLoading] = React.useState(false);
  const [from, setFrom] = React.useState(now.toDate());
  const [to, setTo] = React.useState(now.toDate());

  const [picker, setPicker] = React.useState<string | boolean>(false);

  const handlePickerToggle = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const {id} = e.currentTarget;

      if (id === 'datepicker-download-from') {
        setPicker('from');
      } else if (id === 'datepicker-download-to') {
        setPicker('to');
      } else {
        setPicker(false);
      }
    },
    []
  );

  const handleDateSelect = React.useCallback(
    (date: Date) => {
      switch (picker) {
        case 'from':
          if (moment(date).isAfter(to)) {
            return;
          }

          setFrom(date);
          break;
        case 'to':
          if (moment(date).isBefore(from)) {
            return;
          }
          setTo(date);
          break;
      }

      setPicker(false);
    },
    [from, to, picker]
  );

  const handleClosed = React.useCallback(() => setPicker(false), []);

  const handleDownload = React.useCallback(() => {
    setLoading(true);

    (async () => {
      const uuid = '05f49b69-3882-4a4b-bef4-7b2074441862';
      await API.getDeviceData(uuid, {
        from: '2020-01-01',
        to: '2020-02-28',
        type: 'report',
      });

      setLoading(false);
    })();
  }, []);

  return (
    <Modal
      centered
      isOpen={props.isOpen}
      toggle={props.toggle}
      onClosed={handleClosed}
    >
      <ModalHeader toggle={props.toggle}>
        {t('st-nahmdee_download')}
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>{t('st-nahmdee_download_date_range')}</Label>
          <br />
          <div className="d-flex align-items-center">
            <Button
              outline
              id="datepicker-download-from"
              disabled={loading}
              onClick={handlePickerToggle}
            >
              {printDate(from)}
            </Button>
            <Popover
              hideArrow
              trigger="click"
              target="datepicker-download-from"
              isOpen={props.isOpen && picker === 'from'}
              toggle={handleClosed}
              placement="bottom-start"
            >
              <PopoverBody>
                <DayPicker
                  initialMonth={from}
                  selectedDays={from}
                  toMonth={now.toDate()}
                  onDayClick={handleDateSelect}
                />
              </PopoverBody>
            </Popover>
            <span className="mx-3">-</span>
            <Button
              outline
              id="datepicker-download-to"
              disabled={loading}
              onClick={handlePickerToggle}
            >
              {printDate(to)}
            </Button>
            <Popover
              hideArrow
              trigger="click"
              target="datepicker-download-to"
              isOpen={props.isOpen && picker === 'to'}
              toggle={handleClosed}
              placement="bottom-start"
            >
              <PopoverBody>
                <DayPicker
                  initialMonth={to}
                  selectedDays={to}
                  toMonth={now.toDate()}
                  onDayClick={handleDateSelect}
                />
              </PopoverBody>
            </Popover>
          </div>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          color="primary"
          disabled={loading}
          onClick={handleDownload}
        >
          {t('st-nahmdee_download_button')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default STNahmdeeDownloadModal;

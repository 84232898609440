import { History } from 'history';
import { applyMiddleware, createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { all } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';

import { sagas as authSagas, reducer as authReducer } from '../auth';
import { sagas as deviceSagas, reducer as deviceReducer } from '../device';
import { sagas as stationSagas, reducer as stationReducer } from '../station';

function* rootSagas() {
  yield all([...authSagas, ...deviceSagas, ...stationSagas]);
}

export function configureStore(history: History) {
  const reducers = combineReducers({
    device: deviceReducer,
    station: stationReducer,
    auth: authReducer
  });

  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    reducers,
    composeWithDevTools(
      applyMiddleware(routerMiddleware(history), sagaMiddleware)
    )
  );

  sagaMiddleware.run(rootSagas);

  return store;
}

import axios from 'axios';

export function login(data: any) {
  return axios({
    method: 'POST',
    url: '/auth/login',
    data,
  });
}

export function checkMe() {
  return axios({
    method: 'GET',
    url: '/auth/check-me',
  });
}

import * as React from 'react';
import {useTranslation} from 'react-i18next';

import {Card, CardBody, CardHeader} from 'reactstrap';

import * as API from '../../api';
import {ControlButton, ControlAutoImage, ControlManualImage} from '../STEngine';

interface ControlPanelProps {
  uuid: string;
  mode: string;
}

export const ControlPanel: React.FC<ControlPanelProps> = (
  props: ControlPanelProps
) => {
  const {mode, uuid} = props;
  const {t} = useTranslation();

  const handleAction = React.useCallback(
    (value: string) => {
      (async () => {
        await API.controlDevice({
          uuid: uuid,
          control: 'autoMode',
          value: value === 'auto',
        });
      })();
    },
    [uuid]
  );

  return (
    <Card className="h-100">
      <CardHeader>
        <span className="font-weight-bold">{t('control_panel')}</span>
      </CardHeader>
      <CardBody>
        <div className="row">
          <div className="col-6 mb-3 text-center">
            <ControlButton
              className="action-button mb-2"
              disabled={mode === 'auto'}
              onClick={() => handleAction('auto')}
            >
              <ControlAutoImage />
            </ControlButton>
            <span>
              <span
                className={`led led-${
                  mode === 'auto' ? 'success' : 'idle'
                } mr-3`}
              />
              {t('st-waterpump_auto_mode')}
            </span>
          </div>
          <div className="col-6 text-center">
            <ControlButton
              className="action-button mb-2"
              disabled={mode === 'manual'}
              onClick={() => handleAction('manual')}
            >
              <ControlManualImage />
            </ControlButton>
            <span>
              <span
                className={`led led-${
                  mode === 'manual' ? 'success' : 'idle'
                } mr-3`}
              />
              {t('st-waterpump_manual_mode')}
            </span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ControlPanel;

import * as React from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {Button} from 'reactstrap';

import * as API from '../api';
import {ContentContainer, Icon, Loadable} from '../common';

import {FormValues, StationForm} from './StationForm';

export const StationEdit: React.FC<{}> = () => {
  const [station, setStation] = React.useState<any>({});
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();
  const {id} = useParams();

  const handleSubmit = React.useCallback((values: FormValues) => {
    const {code, ...data} = values;

    (async () => {
      await API.updateStation({...data, id});
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      const res = await API.getStation({id});
      setStation(res.data.station);
      setLoading(false);
    })();
  }, [id]);

  return (
    <Loadable loading={loading}>
      {() => (
        <ContentContainer>
          <div className="d-flex align-items-center my-4">
            <Button
              color="text-muted"
              type="button"
              className="mr-3 p-0"
              onClick={history.goBack}
            >
              <Icon name="ChevronLeft" />
            </Button>
            <h2 className="font-weight-bold mb-0 mr-3">แก้ไข {station.name}</h2>
          </div>
          <StationForm
            mode="edit"
            values={station}
            disabled={loading}
            onSubmit={handleSubmit}
          />
        </ContentContainer>
      )}
    </Loadable>
  );
};

export default StationEdit;

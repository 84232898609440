import * as React from 'react';
import moment from 'moment';
import keys from 'lodash/keys';
import {Table} from 'reactstrap';
import {Icon} from '../../common';
import {getActivityObj} from './getActivityObj';

interface StationActivityTableProps {
  time: any;
  activities: any;
  max?: number;
}

const StationActivityTable: React.FunctionComponent<
  StationActivityTableProps
> = (props: StationActivityTableProps) => {
  moment.locale('th');

  const renderRow = React.useCallback((h: any) => {
    const src = h.source;

    let obj: any = null;

    if (!src) {
      return null;
    }

    keys(src.payload).forEach((k: string) => {
      if (obj != null) {
        return;
      }

      obj = getActivityObj(k, src.payload[k]);
    });

    if (!obj) {
      return null;
    }

    return (
      <tr key={h.id}>
        <td>{moment(src['@timestamp']).format('DD/MM/YY HH:mm')}</td>
        <td>
          <Icon className={`mr-3 text-${obj.intent}`} name={obj.icon} />
          <span>{obj.content}</span>
        </td>
      </tr>
    );
  }, []);

  if (!props.activities) {
    return <div className="activity-empty">There is no activity.</div>;
  }

  const {hits} = props.activities;
  const max = props.max || hits.length;

  let objs: any[] = hits;

  if (max && max > 0) {
    objs = objs.slice(0, max);
  }

  return (
    <div className="activity-table">
      {hits.length ? (
        <Table borderless size="md">
          <tbody>{objs.map((h: any) => renderRow(h))}</tbody>
        </Table>
      ) : (
        <div className="activity-empty">There is no activity.</div>
      )}
    </div>
  );
};

export default StationActivityTable;

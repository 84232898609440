import * as React from 'react';
import {Route} from 'react-router-dom';

import {UserNewPage} from './UserNewPage';
import {UserEditPage} from './UserEditPage';
import {SettingsPage} from './SettingsPage';
import {UserIndexPage} from './UserIndexPage';
import {StationStaffPage} from './StationStaffPage';

export const Routes = [
  <Route
    key="organization-settings"
    exact
    path="/organization/settings"
    component={SettingsPage}
  />,
  <Route
    key="organization-users"
    exact
    path="/organization/users"
    component={UserIndexPage}
  />,
  <Route
    key="organiztion-users-create"
    path="/organization/users/new"
    component={UserNewPage}
    exact
  />,
  <Route
    key="organiztion-users-create"
    path="/organization/users/:id/edit"
    component={UserEditPage}
  />,
  <Route
    key="organization-station-staffs"
    path="/organization/stations/:id/staffs"
    component={StationStaffPage}
  />,
];

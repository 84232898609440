import * as React from 'react';
import {useTranslation} from 'react-i18next';
import DayPicker from 'react-day-picker';
import moment from 'moment';

import {Button, ButtonGroup, Popover, PopoverBody} from 'reactstrap';

import {DateRange, printDateRange} from './helper';

interface STNahmdeeToolbarProps {
  dateRange: DateRange;
  view: string;
  onDateRangeChange: (dateRange: DateRange) => void;
  onViewChange: (view: string) => void;
}

export const STNahmdeeToolbar: React.FC<STNahmdeeToolbarProps> = (
  props: STNahmdeeToolbarProps
) => {
  const {dateRange, onDateRangeChange, onViewChange} = props;

  const [period, setPeriod] = React.useState('day');
  const [toggle, setToggle] = React.useState(false);

  const {t} = useTranslation();

  const handleViewChange = React.useCallback(
    (e: React.FormEvent<HTMLButtonElement>) => {
      const {name} = e.currentTarget;
      onViewChange(name);
    },
    []
  );

  const handleDateSelect = React.useCallback(
    (date: Date) => {
      const p = period as 'day' | 'week' | 'month';

      const from = moment(date)
        .startOf(p)
        .toDate();

      const to = moment(date)
        .endOf(p)
        .toDate();

      onDateRangeChange({from, to});
      setToggle(false);
    },
    [period]
  );

  const handlePeriodChange = React.useCallback(
    (e: React.FormEvent<HTMLButtonElement>) => {
      const {name} = e.currentTarget;
      const p = name as 'day' | 'week' | 'month';

      if (p === period) {
        return;
      }

      const from = moment(dateRange.from)
        .startOf(p)
        .toDate();

      const to = moment(dateRange.from)
        .endOf(p)
        .toDate();

      setPeriod(p);
      onDateRangeChange({from, to});
      setToggle(false);
    },
    [period, dateRange]
  );

  const handleToggle = React.useCallback(() => setToggle(!toggle), [toggle]);

  return (
    <div className="d-flex mb-3">
      <div className="mb-2">
        <Button id="datepicker-popover" outline className="mr-2">
          {printDateRange(dateRange)}
        </Button>
        <Popover
          hideArrow
          trigger="click"
          target="datepicker-popover"
          isOpen={toggle}
          toggle={handleToggle}
          placement="bottom-start"
        >
          <PopoverBody>
            <DayPicker
              initialMonth={dateRange.from}
              selectedDays={dateRange}
              onDayClick={handleDateSelect}
            />
          </PopoverBody>
        </Popover>
        <ButtonGroup>
          <Button
            outline
            name="day"
            active={period === 'day'}
            onClick={handlePeriodChange}
          >
            {t('period_day')}
          </Button>
          <Button
            outline
            name="week"
            active={period === 'week'}
            onClick={handlePeriodChange}
          >
            {t('period_week')}
          </Button>
          <Button
            outline
            name="month"
            active={period === 'month'}
            onClick={handlePeriodChange}
          >
            {t('period_month')}
          </Button>
        </ButtonGroup>
      </div>
      <div className="ml-auto">
        <ButtonGroup>
          <Button
            outline
            name="graph"
            active={props.view === 'graph'}
            onClick={handleViewChange}
          >
            {t('view_graph')}
          </Button>
          <Button
            outline
            name="table"
            active={props.view === 'table'}
            onClick={handleViewChange}
          >
            {t('view_table')}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default STNahmdeeToolbar;

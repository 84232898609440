import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {Button, NavbarBrand, Navbar, Nav, NavItem} from 'reactstrap';

import {AuthContext} from '../../auth';
import Icon from '../../common/Icon';
import logo from 'assets/images/logo.jpg';

export const Header: React.SFC<{}> = () => {
  const history = useHistory();
  const {logout} = React.useContext(AuthContext);

  return (
    <React.Fragment>
      <Navbar color="light" light expand>
        <NavbarBrand
          className="py-0"
          href="#"
          onClick={() => history.push('/home/stations')}
        >
          <img alt="logo" src={logo} style={{height: '39px'}} />
        </NavbarBrand>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <Button
              id="action-setting"
              color="text-muted"
              className="p-0"
              onClick={() => history.push('/organization/settings')}
            >
              <Icon
                className="align-middle mr-2"
                name="Settings"
                size="small"
              />
            </Button>
          </NavItem>
          <NavItem>
            <Button
              id="action-logout"
              color="text-muted"
              className="ml-2 p-0"
              onClick={logout}
            >
              <Icon className="align-middle mr-2" name="LogOut" size="small" />
            </Button>
          </NavItem>
        </Nav>
      </Navbar>
    </React.Fragment>
  );
};

export default Header;

import * as React from 'react';
import * as Feather from 'react-feather';

interface IconProps {
  name: string;
  size?: string | number;
  className?: string;
}

const Icon: React.SFC<IconProps> = (props: IconProps) => {
  const IconComponent = Feather[props.name];
  return (
    <span className={`icon ${props.size} ${props.className}`}>
      {IconComponent && <IconComponent />}
    </span>
  );
};

Icon.defaultProps = {
  name: '',
  size: 'small',
  className: '',
};

export default Icon;

import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Button, ButtonGroup} from 'reactstrap';

interface STNahmdeeModeSelector {
  mode: string;
  onModeChange: (mode: string) => void;
}

export const STNahmdeeModeSelector: React.FC<STNahmdeeModeSelector> = (
  props: STNahmdeeModeSelector
) => {
  const {mode, onModeChange} = props;

  const {t} = useTranslation();

  const handleModeChange = React.useCallback(
    (e: React.FormEvent<HTMLButtonElement>) => {
      const {name} = e.currentTarget;
      onModeChange(name);
    },
    []
  );

  return (
    <ButtonGroup className="mr-3">
      <Button
        outline
        name="temperature"
        active={mode === 'temperature'}
        onClick={handleModeChange}
      >
        {t('value_temperature')}
      </Button>
      <Button
        outline
        name="do"
        active={mode === 'do'}
        onClick={handleModeChange}
      >
        {t('value_oxygen')}
      </Button>
    </ButtonGroup>
  );
};

export default STNahmdeeModeSelector;

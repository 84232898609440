import React from 'react';
import moment from 'moment';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'react-vis/dist/style.css';
import 'react-day-picker/lib/style.css';

import App from './App';
import {AuthContextProvider} from './auth';
import './../styles/main.scss';

import {configureAxios, configureStore} from './config';

moment.locale('th');

declare global {
  interface Window {
    APIEndpoint: string;
    GoogleMapAPIKey: string;
  }
}

let ApiEndpoint;

if (typeof API_ENDPOINT !== 'undefined') {
  ApiEndpoint = API_ENDPOINT;
} else {
  const {origin} = window.location;
  ApiEndpoint = `${origin}/api`;
}

window.APIEndpoint = ApiEndpoint;

configureAxios({
  baseUrl: ApiEndpoint,
  loginUrl: '/login',
  authKey: 'authToken',
});

const history = createBrowserHistory();
const store = configureStore(history);

ReactDOM.render(
  <Provider store={store}>
    <AuthContextProvider>
      <Router>
        <App />
      </Router>
    </AuthContextProvider>
  </Provider>,
  document.getElementById('root')
);

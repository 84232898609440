import axios from 'axios';

function getDevices() {
  return axios({
    method: 'GET',
    url: '/devices'
  });
}

function getDevice(payload: any) {
  return axios({
    method: 'GET',
    url: `/devices/${payload.uuid}`
  });
}

function controlDevice(payload: any) {
  return axios({
    method: 'POST',
    url: `/devices/${payload.uuid}/control`,
    data: payload
  });
}

export const api = {
  getDevices,
  getDevice,
  controlDevice
};

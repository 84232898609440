import styled from 'styled-components';
import {clearFix} from 'polished';

import {themeConstants as c} from '../../theme';

export const Container = styled.div`
  &:after {
    content: '';
    display: table;
    clear: both;
  }
`;

export const ListContainer = styled.div`
  float: left;
  width: 40%;
  height: calc(100vh - 92px);
  overflow-y: scroll;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

interface ListItemIconProps {
  pointer?: boolean;
}

export const ListItemIcon = styled.div<ListItemIconProps>`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  width: 40px;
  align-items: center;

  cursor: ${props => (props.pointer ? 'pointer' : 'default')};
`;

export const ListItemImage = styled.img`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  max-width: 132px;
  align-items: center;
  background-color: #dfdfdf;
`;

export const ListItem = styled.div`
  display: flex;
  border-bottom: ${props => props.theme.border};
  padding: ${c.contentPadding} 0px;
  cursor: pointer;

  &:hover > ${ListItemIcon} {
    color: blue;
  }

  ${clearFix()}
`;

export const ListItemContent = styled.div`
  flex-grow: 1;
  margin-left: 16px;
`;

export const MapContainer = styled.div`
  float: left;
  width: 60%;
  height: calc(100vh - 92px);

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const FilterBar = styled.div`
  height: 48px;
  line-height: 48px;
  padding: 0px ${c.contentPadding};
  border-bottom: ${props => props.theme.border};
`;

export const Topbar = styled.div`
  display: flex;
  height: 48px;
  line-height: 48px;
  padding: 0px 15px;
  border-bottom: ${props => props.theme.border};
`;

export const TopbarLeftPanel = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const TopbarRightPanel = styled.div`
  display: flex;
  flex-shrink: 0;
`;

export const TopbarNewButton = styled.button`
  height: 34px;
  margin-top: 7px;
`;

export const SearchInput = styled.input`
  height: 34px;
  margin-top: 7px;
  margin-left: 7px;
  border: none;
  border-bottom: ${props => props.theme.border};

  &:focus {
    border-bottom-color: #747474;
  }
`;

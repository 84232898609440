import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps';

const baseMapURL =
  'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places';

const key = 'AIzaSyBYkXd7y32PbX5v0GLXrl-8KsEqG6TqECs';

const composeProps = withProps((props: any) => ({
  googleMapURL: `${baseMapURL}&key=${key}`,
  loadingElement: <div style={{ height: '100%' }} />,
  containerElement: <div style={{ height: `${props.height}px` }} />,
  mapElement: <div style={{ height: '100%' }} />
}));

const Map = compose(
  composeProps,
  withScriptjs,
  withGoogleMap
)((props: any) => (
  <GoogleMap
    defaultZoom={props.zoom}
    defaultCenter={{ lat: props.center.lat, lng: props.center.long }}
  >
    {props.markers.map((m: any, i: number) => (
      <Marker key={i} position={{ lat: m.lat, lng: m.long }} />
    ))}
  </GoogleMap>
));

const coordiatePropType = PropTypes.shape({
  lat: PropTypes.number,
  long: PropTypes.number
});

Map.propTypes = {
  height: PropTypes.number,
  zoom: PropTypes.number,
  center: coordiatePropType,
  markers: PropTypes.arrayOf(coordiatePropType)
};

Map.defaultProps = {
  height: 480,
  isMarkerShown: false,
  zoom: 10,
  center: {
    lat: 13.736717,
    long: 100.523186
  },
  markers: []
};

export default Map;

import { types } from './types';

export function getDevices() {
  return { type: types.GET_DEVICES_REQUEST };
}

export function getDevice(uuid: string) {
  return { type: types.GET_DEVICE_REQUEST, payload: { uuid } };
}

export function controlDevice(uuid: string, control: string, value: any) {
  return {
    type: types.CONTROL_DEVICE_REQUEST,
    payload: { uuid, control, value }
  };
}

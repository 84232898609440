import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

import * as API from '../api';
import {ContentContainer, TitleBar} from '../common';

import {FormValues, StationForm} from './StationForm';

export const StationNew: React.FC<{}> = () => {
  const history = useHistory();
  const {t} = useTranslation();

  const [loading, setLoading] = React.useState(false);

  const handleSubmit = React.useCallback((values: FormValues) => {
    setLoading(true);

    (async () => {
      try {
        const res = await API.createStation(values);
        const station = res.data;
        history.push(`/stations/${station.id}`);
      } catch (e) {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <ContentContainer>
      <TitleBar title={t('station_new')} />
      <StationForm mode="new" disabled={loading} onSubmit={handleSubmit} />
    </ContentContainer>
  );
};

export default StationNew;

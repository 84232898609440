import * as React from 'react';

import {Icon} from '../../common';
import {DataBox, DataBoxIcon, DataBoxContent} from './Styles';

interface DeviceDataBoxProps {
  icon?: string;
  title: string;
  children?: any;
}

export const DeviceDataBox: React.FC<DeviceDataBoxProps> = (
  props: DeviceDataBoxProps
) => {
  const {icon, title, children} = props;

  return (
    <DataBox>
      {icon && (
        <DataBoxIcon>
          <Icon name="Radio" size="medium" />
        </DataBoxIcon>
      )}
      <DataBoxContent>
        <h6>{title}</h6>
        {children}
      </DataBoxContent>
    </DataBox>
  );
};

export default DeviceDataBox;

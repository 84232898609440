import { delay, call, takeEvery } from 'redux-saga/effects';

import { createAPISaga, toast } from '../../util';
import { api } from './api';
import { types } from './types';

const getStations = createAPISaga(api.getStations);
const createStation = createAPISaga(api.createStation);
const getStation = createAPISaga(api.getStation);
const updateStation = createAPISaga(api.updateStation);
const getStationActivities = createAPISaga(api.getStationActivities);

function* createStationSuccess() {
  yield call(toast.success, 'Station created');
  yield delay(1000);

  const data: any = {
    action: 'navigate',
    path: '/stations'
  };

  document.dispatchEvent(new MessageEvent('message', { data }));
}

function* updateStationSuccess() {
  yield call(toast.success, 'Station updated');
}

export const sagas = [
  takeEvery(types.GET_STATIONS_REQUEST, getStations),
  takeEvery(types.CREATE_STATION_REQUEST, createStation),
  takeEvery(types.CREATE_STATION_SUCCESS, createStationSuccess),
  takeEvery(types.UPDATE_STATION_REQUEST, updateStation),
  takeEvery(types.UPDATE_STATION_SUCCESS, updateStationSuccess),
  takeEvery(types.GET_STATION_REQUEST, getStation),
  takeEvery(types.GET_STATION_ACTIVITIES_REQUEST, getStationActivities)
];

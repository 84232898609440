import produce, {Draft} from 'immer';

export interface AuthState {
  authenticated: boolean;
  authToken: string | null;
  user: any | null;
  loading: boolean;
  logout: () => void;
}

export const initialState: AuthState = {
  authenticated: false,
  authToken: window.localStorage.getItem('authToken'),
  user: null,
  loading: false,
  logout: () => {},
};

const SET_LOADING = 'auth/SET_LOADING';
const SET_AUTHENTICATED = 'auth/SET_AUTHENTICATED';

export function setAuthenticated(user: any) {
  return {type: SET_AUTHENTICATED, authenticated: true, user};
}

export function setLoading(loading: boolean) {
  return {type: SET_LOADING, loading};
}

export const reducer = produce((draft: Draft<AuthState>, action: any) => {
  switch (action.type) {
    case SET_LOADING:
      draft.loading = action.loading;
      break;
    case SET_AUTHENTICATED:
      draft.authenticated = true;
      draft.user = action.user;
      break;
    default:
      break;
  }
});

import * as React from 'react';
import {useTranslation} from 'react-i18next';
import AsyncSelect from 'react-select/async';
import {Button} from 'reactstrap';

import * as API from '../../api';

interface UserInputProps {
  onSelect?: (user: any) => void;
  filterOption?: (data: any) => boolean;
}

export const UserInput: React.FC<UserInputProps> = (props: UserInputProps) => {
  const {filterOption, onSelect} = props;

  const {t} = useTranslation();

  const [selected, setSelected] = React.useState<any>(null);
  const selectEl = React.useRef(null);

  const handleLoad = React.useCallback((value: string, callback: any) => {
    if (!value.length) {
      callback([]);
      return;
    }

    (async () => {
      const res = await API.searchOrganizationUsers(value);
      callback(res.data);
    })();
  }, []);

  const handleAdd = React.useCallback(() => {
    onSelect && onSelect(selected);
    setSelected(null);
  }, [selected, onSelect]);

  return (
    <>
      <AsyncSelect
        ref={selectEl}
        isClearable
        loadOptions={handleLoad}
        defaultOptions
        value={selected}
        getOptionLabel={(opt: any) =>
          `${opt.firstName} ${opt.lastName} <${opt.email}>`
        }
        onChange={(opt: any) => setSelected(opt)}
        filterOption={filterOption}
      />
      <Button type="button" disabled={selected === null} onClick={handleAdd}>
        {t('general_add')}
      </Button>
    </>
  );
};

export default UserInput;

import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Button } from 'reactstrap';

import {ContentContainer, Icon} from '../common';
import { getStationActivities, stationActivityInit } from './modules';
import StationActivityTable from './components/StationActivityTable';

interface StationActivityProps extends DispatchProp, RouteComponentProps {
  loading: boolean;
  view: any;
}

const StationActivity: React.FunctionComponent<StationActivityProps> = (
  props: StationActivityProps
) => {
  const params = props.match.params as any;

  const [page, setPage] = React.useState(1);

  const handleSearch = React.useCallback(() => {
    setPage(page + 1);
  }, [page]);

  // const { view } = props;
  const data = props.view.data || {};
  const { activities } = data;

  React.useEffect(() => {
    props.dispatch(stationActivityInit());
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      props.dispatch(
        getStationActivities({ id: params.id, page }, 'stationActivity')
      );
    }, 100);
  }, [page]);

  if (!data.page) {
    return null;
  }

  const totalValue = activities ? activities.total.value : 0;

  return (
    <ContentContainer>
      <div className="d-flex align-items-center my-4">
        <Button
          color="text-muted"
          type="button"
          className="mr-3 p-0"
          onClick={props.history.goBack}
        >
          <Icon name="ChevronLeft" />
        </Button>
        <h2 className="font-weight-bold mb-0 mr-3">ลำดับเหตุการณ์</h2>
      </div>
      <hr />
      <StationActivityTable activities={activities} time={new Date()} />
      {totalValue > 0 && totalValue !== activities.hits.length && (
        <div className="text-center">
          <Button
            size="lg"
            color="primary"
            type="button"
            onClick={handleSearch}
            disabled={totalValue === activities.hits.length}
          >
            Load More
          </Button>
        </div>
      )}
    </ContentContainer>
  );
};

const mapStateToProps = (state: any) => ({
  view: state.station.stationActivity
});

export default connect(mapStateToProps)(StationActivity);

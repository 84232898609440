import * as React from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useAsync} from 'react-use';

import {Button, Card, CardBody, CardHeader, Col, Row, Table} from 'reactstrap';

import {
  ContentContainer,
  Icon,
  Loading,
  StationMap,
  TitleBar,
} from '../../common';
import * as API from '../../api';

import {DevicePreview} from '../../device';
import StationActivityTable from '../components/StationActivityTable';

import {MapContainer} from './Styles';

export const StationPage: React.SFC<{}> = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();

  const {id} = match.params;

  const state = useAsync(async () => {
    const res = await API.getStation({id});
    return res.data;
  }, [id]);

  if (state.loading) {
    return <Loading />;
  }

  if (!state.value) {
    return <Loading />;
  }
  const {station, activities} = state.value;
  const devices = station.devices || [];

  return (
    <ContentContainer>
      <TitleBar title={station.name}>
        <Button
          color="text-muted"
          className="ml-auto p-0"
          onClick={() => history.push(`/organization/stations/${id}/staffs`)}
        >
          <Icon name="Users" />
        </Button>
      </TitleBar>
      <div className="container">
        <MapContainer>
          <StationMap
            stations={[station]}
            markers={[]}
            defaultCenter={{lat: station.latitude, lng: station.longitude}}
          />
        </MapContainer>
        <Row>
          <Col className="mb-4" md={6}>
            <Card className="mb-0 h-100">
              <CardHeader>
                <div className="d-flex align-items-center">
                  <span className="font-weight-bold">
                    {t('station_information')}
                  </span>
                  <Button
                    color="text-muted"
                    className="ml-auto p-0"
                    onClick={() => history.push(`/stations/${id}/edit`)}
                  >
                    <Icon name="Settings" />
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <Table borderless size="md">
                  <tbody>
                    <tr>
                      <td>{t('station_name')}</td>
                      <td>{station.name}</td>
                    </tr>
                    <tr>
                      <td>{t('station_code')}</td>
                      <td>{station.code}</td>
                    </tr>
                    <tr>
                      <td>{t('station_staff_name')}</td>
                      <td>{station.staffName}</td>
                    </tr>
                    <tr>
                      <td>{t('station_staff_tel')}</td>
                      <td>{station.staffTel}</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col className="mb-4" md={6}>
            <Card className="mb-0 h-100">
              <CardHeader>
                <div className="d-flex align-items-center">
                  <span className="font-weight-bold">
                    {t('station_activities')}
                  </span>
                  <Button
                    color="text-muted"
                    className="ml-auto p-0 text-muted"
                    onClick={() => history.push(`/stations/${id}/activity`)}
                  >
                    <Icon name="List" />
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <StationActivityTable
                  activities={activities}
                  max={4}
                  time={new Date()}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="station-device-previews">
          {devices.map((device: any) => (
            <DevicePreview key={device.uuid} device={device} />
          ))}
        </div>
      </div>
    </ContentContainer>
  );
};

/*
interface StationDetailProps extends DispatchProp, RouteComponentProps {
  view: any;
}

const StationPage: React.FunctionComponent<StationDetailProps> = (
  props: StationDetailProps
) => {
  const {t} = useTranslation();

  const handleEdit = React.useCallback(() => {
    const params: any = props.match.params;
    props.history.push(`/stations/${params.id}/edit`);
  }, []);

  React.useEffect(() => {
    const params: any = props.match.params;

    props.dispatch(getStation({id: params.id}, 'stationDetail'));
  }, []);

  const station = props.view.data ? props.view.data.station : null;
  const activities = props.view.data ? props.view.data.activities : {};

  return (
    <Loadable loading={props.view.loading || !station}>
      {() => (
        <ContentContainer>
          <div className="d-flexa  align-items-center my-4">
            <Button
              color="text-muted"
              type="button"
              className="mr-3 p-0 float-left align-middle mt-1"
              onClick={props.history.goBack}
            >
              <Icon name="ChevronLeft" />
            </Button>
            <h2 className="mb-0 mr-3">{station.name}</h2>
          </div>
          <div className="mb-4">
            <DetailMapContainer>
              <StationMap
                stations={[station]}
                markers={[]}
                defaultCenter={{lat: station.latitude, lng: station.longitude}}
              />
            </DetailMapContainer>
          </div>
          <Row>
            <Col className="mb-4" md={6}>
              <Card className="mb-0">
                <CardHeader>
                  <div className="d-flex align-items-center">
                    <span className="font-weight-bold">
                      {t('station_information')}
                    </span>
                    <Button
                      color="text-muted"
                      className="ml-auto p-0"
                      onClick={handleEdit}
                    >
                      <Icon name="Settings" />
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <Table borderless size="sm">
                    <tbody>
                      <tr>
                        <td>{t('station_name')}</td>
                        <td>{station.name}</td>
                      </tr>
                      <tr>
                        <td>{t('station_code')}</td>
                        <td>{station.code}</td>
                      </tr>
                      <tr>
                        <td>{t('station_staff_name')}</td>
                        <td>{station.staffName}</td>
                      </tr>
                      <tr>
                        <td>{t('station_staff_tel')}</td>
                        <td>{station.staffTel}</td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            <Col className="mb-4" md={6}>
              <Card className="mb-0">
                <CardHeader>
                  <div className="d-flex align-items-center">
                    <span className="font-weight-bold">
                      {t('station_activities')}
                    </span>
                    <Link
                      className="ml-auto text-muted"
                      to={`/stations/${station.id}/activity`}
                    >
                      <Icon name="List" />
                    </Link>
                  </div>
                </CardHeader>
                <CardBody>
                  <StationActivityTable
                    activities={activities}
                    max={4}
                    time={new Date()}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="station-device-previews">
            {station.devices &&
              station.devices.map((device: any) => (
                <DevicePreview key={device.uuid} device={device} />
              ))}
          </div>
        </ContentContainer>
      )}
    </Loadable>
  );
};

const mapStateToProps = (state: any) => ({
  view: state.station.stationDetail,
});

export default connect(mapStateToProps)(StationDetail);
*/

import axios from 'axios';

export function getStation({id}: any) {
  return axios({
    method: 'GET',
    url: `/stations/${id}`,
  });
}

export async function getStations() {
  return axios({
    method: 'GET',
    url: '/stations',
  }).then(res => res.data);
}

export function getStationActivities({id, from, to, page}: any) {
  const params: any = {};

  if (from && to) {
    params.from = from;
    params.to = to;
  }

  if (page) {
    params.page = page;
  }

  return axios({
    method: 'GET',
    url: `/stations/${id}/activities`,
    params,
  });
}

export function createStation(data: any) {
  return axios({
    method: 'POST',
    url: '/stations',
    data,
  });
}

export function updateStation(data: any) {
  return axios({
    method: 'PUT',
    url: `/stations/${data.id}`,
    data,
  });
}

export function uploadStationImage(id: any, data: any) {
  return axios({
    method: 'POST',
    url: `/stations/${id}/image`,
    data,
  });
}

export function getStationImage(id: any) {
  return axios({
    method: 'GET',
    url: `stations/${id}/image`,
  });
}

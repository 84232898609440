import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import { Card, CardHeader, CardBody } from 'reactstrap';

import {ContentContainer, Icon} from '../../common';
import {SettingItem, SettingList} from './Styles';

export const SettingsPage: React.FC<{}> = () => {
  const history = useHistory();
  const {t} = useTranslation();

  return (
    <ContentContainer>
      <div className="my-4">
        <h2>{t('organization_settings')}</h2>
      </div>
      <SettingList>
        <SettingItem
          className="p-0 border-0"
          onClick={() => history.push('/organization/users')}
        >
          <Card>
            <CardHeader>
              <div className="d-flex align-items-center">
                <span className="font-weight-bold">
                  {t('organization_settings_user')}
                </span>
                <Icon
                  name="Users"
                  className="text-muted ml-auto p-0"
                />
              </div>
            </CardHeader>
            <CardBody>{t('organization_settings_user_description')}</CardBody>
          </Card>
        </SettingItem>
      </SettingList>
    </ContentContainer>
  );
};

export default SettingsPage;

import * as React from 'react';
import {useForm} from 'react-hook-form';

import {
  Button,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

import logo from 'assets/images/logo.jpg';

import * as API from '../api';
import {Icon} from '../common';
import {AuthWrapper, LoginCard, LoginContainer} from './AuthStyles';

interface FormValues {
  email: string;
  password: string;
}

export const AuthLogin: React.FC<{}> = () => {
  const [loading, setLoading] = React.useState(false);
  const {register, handleSubmit} = useForm<FormValues>();

  const login = React.useCallback((data: FormValues) => {
    setLoading(true);

    (async () => {
      try {
        const res = await API.login(data);
        const {token} = res.data;

        window.localStorage.setItem('authToken', token);

        setTimeout(() => {
          window.location.href = '/home/stations';
        }, 1000);
      } catch (e) {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <AuthWrapper>
      <LoginContainer>
        <LoginCard className="login-form px-4 py-5">
          <CardBody>
            <div className="px-2 text-center mb-5">
              <img alt="logo" src={logo} style={{width: '50%'}} />
            </div>
            <form onSubmit={handleSubmit(login)}>
              <fieldset disabled={loading}>
                <InputGroup className="mb-4">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <Icon name="User" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="อีเมล"
                    ref={register}
                  />
                </InputGroup>
                <InputGroup className="mb-4">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <Icon name="Lock" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="รหัสผ่าน"
                    ref={register}
                  />
                </InputGroup>
                <Button type="submit" color="primary" className="px-4">
                  เข้าสู่ระบบ
                </Button>
              </fieldset>
            </form>
          </CardBody>
        </LoginCard>
      </LoginContainer>
    </AuthWrapper>
  );
};

export default AuthLogin;

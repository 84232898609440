import * as React from 'react';
import {Route} from 'react-router-dom';

import StationIndex from './StationIndex';
import StationActivity from './StationActivity';
import StationNew from './StationNew';
import StationEdit from './StationEdit';

import {StationPage} from './StationPage';

export const routes = [
  <Route key="station-new" exact path="/stations/new" component={StationNew} />,
  <Route
    key="station-edit"
    exact
    path="/stations/:id/edit"
    component={StationEdit}
  />,
  <Route
    key="station-detail"
    exact
    path="/stations/:id"
    component={StationPage}
  />,
  <Route
    key="station-activity"
    exact
    path="/stations/:id/activity"
    component={StationActivity}
  />,
  <Route key="station-index" exact path="/stations" component={StationIndex} />,
];

import { call, put, take } from 'redux-saga/effects';
import { AxiosPromise } from 'axios';

export interface Action {
  type: string;
  payload?: any;
  sender: string;
}

type API<T> = (ps?: T) => AxiosPromise;

export function createAPISaga(api: API<any>) {
  return function* apiSaga(action: Action) {
    const successType = action.type.replace(/REQUEST/, 'SUCCESS');
    const failureType = action.type.replace(/REQUEST/, 'FAILURE');

    try {
      const response = yield call(api, action.payload);
      const { data } = response;

      if (data.error) {
        throw new Error(data.error);
      }

      yield put({
        type: successType,
        payload: { response, data },
        sender: action.sender,
        params: action.payload
      });
    } catch (error) {
      yield put({
        type: failureType,
        sender: action.sender,
        error,
        message: error.toString()
      });
    }
  };
}

export function* callSaga(type: string, params?: any) {
  params = params || {};

  const successType = type.replace(/REQUEST/, 'SUCCESS');
  const failureType = type.replace(/REQUEST/, 'FAILURE');

  yield put({ type, ...params });

  return yield take([successType, failureType]);
}

import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Button} from 'reactstrap';
import {ConfirmModal, ContentContainer, Paginate, TitleBar, Icon} from '../../common';
import {usePagination} from '../../util';
import * as API from '../../api';

export const UserIndexPage: React.FC<{}> = () => {
  const history = useHistory();
  const {t} = useTranslation();

  const [deleteId, setDeleteId] = React.useState<number | false>(false);

  const {page, setPage, totalPages, items, refresh} = usePagination(
    API.getOrganizationUsers
  );

  const handleItemClick = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const {id} = e.currentTarget;
      const comps = id.match(/(\S+)-(\S+)/i) || [];

      switch (comps[1]) {
        case 'edit':
          history.push(`/organization/users/${comps[2]}/edit`);
          break;
        case 'delete':
          setDeleteId(parseInt(comps[2], 10));
          break;
      }
    },
    [history]
  );

  const handleDelete = React.useCallback(() => {
    (async () => {
      deleteId && (await API.deleteOrganizationUser(deleteId));
      setDeleteId(false);

      refresh();
    })();
  }, [deleteId, refresh]);

  return (
    <ContentContainer>
      <TitleBar backButton title={t('organization_users')}>
        <Button
          color="primary"
          type="button"
          onClick={() => history.push('/organization/users/new')}
        >
          <Icon
            className="mr-2"
            name="Plus"
          />
          {t('organization_users_new')}
        </Button>
      </TitleBar>
      <table className="table">
        <thead>
          <tr>
            <th>{t('id')}</th>
            <th>{t('organization_users_name')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {items.map((item: any) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>
                {item.firstName} - {item.lastName}
              </td>
              <td className="text-right">
                <Button
                  id={`edit-${item.id}`}
                  type="button"
                  onClick={handleItemClick}
                >
                  <Icon name="Edit" />
                </Button>
                <Button
                  id={`delete-${item.id}`}
                  type="button"
                  className="ml-2"
                  color="danger"
                  onClick={handleItemClick}
                >
                  <Icon name="Trash2" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Paginate page={page} pages={totalPages} onPaginationClick={setPage} />
      <ConfirmModal
        isOpen={deleteId !== false}
        message={t('general_confirm_delete')}
        onConfirm={handleDelete}
        onCancel={() => setDeleteId(false)}
      />
    </ContentContainer>
  );
};

export default UserIndexPage;

import * as React from 'react';
import {useRefresh} from './useRefresh';

export const usePagination = (fetcher: any) => {
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [error, setError] = React.useState<any>(null);
  const [items, setItems] = React.useState<any[]>([]);

  const {token, refresh} = useRefresh();

  React.useEffect(() => {
    (async () => {
      try {
        const {data} = await fetcher({page});
        setItems(data.items);
        setTotalPages(data.totalPages);
      } catch (e) {
        setError(e);
        setLoading(false);
      }
    })();
  }, [page, token]);

  return {loading, items, page, setPage, totalPages, error, refresh};
};

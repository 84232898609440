import * as React from 'react';
import {Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import {Icon} from './';

interface PaginateProps {
  page: number;
  pages: number;
  onPaginationClick: (page: number) => void;
}

const Paginate: React.SFC<PaginateProps> = props => {
  const handleClick = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const {id} = e.currentTarget;
      props.onPaginationClick(+id);
    },
    []
  );

  const pagePaginationShow = () => {
    const rangeWithDots = [];

    if (props.pages > 1) {
      if (props.pages <= 10) {
        for (let i = 1; i <= props.pages; i++) {
          rangeWithDots.push(
            <PaginationItem
              id={i}
              key={i}
              onClick={handleClick}
              active={props.page === i ? true : false}
              disabled={props.page === i ? true : false}
            >
              <PaginationLink>{i}</PaginationLink>
            </PaginationItem>
          );
        }
      } else if (props.pages > 10) {
        const current = props.page;
        const delta = 2;
        const left = current - delta;
        const right = current + delta + 1;
        const range = [];
        const last = props.pages;
        let l = props.pages;

        for (let i = 1; i <= last; i++) {
          if (i === 1 || i === last || (i >= left && i < right)) {
            range.push(i);
          }
        }

        for (const i of range) {
          if (l) {
            if (i - l === 2) {
              rangeWithDots.push(
                <PaginationItem
                  id={l + 1}
                  key={l + 1}
                  className={l + i}
                  onClick={handleClick}
                  active={props.page === l + 1 ? true : false}
                  disabled={props.page === i ? true : false}
                >
                  <PaginationLink className="text-s">{l + 1}</PaginationLink>
                </PaginationItem>
              );
            } else if (i - l !== 1 && i !== 1) {
              rangeWithDots.push(
                <PaginationItem
                  id={l + 1}
                  key={l + 1}
                  className={l + i}
                  disabled
                >
                  <PaginationLink className="text-s">
                    <Icon name="MoreHorizontal" />
                  </PaginationLink>
                </PaginationItem>
              );
            }
          }
          rangeWithDots.push(
            <PaginationItem
              id={i}
              key={i}
              onClick={handleClick}
              active={props.page === i ? true : false}
              disabled={props.page === i ? true : false}
            >
              <PaginationLink className="text-s">{i}</PaginationLink>
            </PaginationItem>
          );
          l = i;
        }
      }
    }

    return rangeWithDots;
  };

  return (
    <div className="pagination float-right p-3">
      <Pagination>
        {props.page !== 1 && (
          <PaginationItem id={1} onClick={handleClick}>
            <PaginationLink className="text-s">
              <Icon name="ChevronsLeft" />
            </PaginationLink>
          </PaginationItem>
        )}
        {props.page !== 1 && (
          <PaginationItem
            id={props.page !== 1 ? props.page - 1 : props.page}
            onClick={handleClick}
          >
            <PaginationLink className="text-s">
              <Icon name="ChevronLeft" />
            </PaginationLink>
          </PaginationItem>
        )}
        {pagePaginationShow()}
        {props.page !== props.pages && props.pages != 0 && (
          <PaginationItem
            id={props.pages > props.page ? props.page + 1 : props.page}
            onClick={handleClick}
          >
            <PaginationLink className="text-s">
              <Icon name="ChevronRight" />
            </PaginationLink>
          </PaginationItem>
        )}
        {props.page !== props.pages && props.pages != 0 && (
          <PaginationItem id={props.pages} onClick={handleClick}>
            <PaginationLink className="text-s">
              <Icon name="ChevronsRight" />
            </PaginationLink>
          </PaginationItem>
        )}
      </Pagination>
    </div>
  );
};

Paginate.defaultProps = {
  page: 1,
  pages: 1,
  onPaginationClick: () => null,
};

export default Paginate;

import * as React from 'react';
import {Route} from 'react-router-dom';
import {Dashboard} from './Dashboard';
import {HomeStations} from './HomeStations';

export const routes = [
  <Route
    key="home-dashboard"
    exact
    path="/home/dashboard"
    component={Dashboard}
  />,
  <Route
    key="home-stations"
    exact
    path="/home/stations"
    component={HomeStations}
  />,
];

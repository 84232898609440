import * as React from 'react';

import {FilterBar} from './DashboardStyles';

export const DashboardFilter: React.FC<{}> = () => {
  return (
    <FilterBar>
      Dashboard Filter
    </FilterBar>
  );
};

export default DashboardFilter;

export const types = {
  GET_DEVICE_REQUEST: 'device/GET_DEVICE_REQUEST',
  GET_DEVICE_SUCCESS: 'device/GET_DEVICE_SUCCESS',
  GET_DEVICE_FAILURE: 'device/GET_DEVICE_FAILURE',
  GET_DEVICES_REQUEST: 'device/GET_DEVICES_REQUEST',
  GET_DEVICES_SUCCESS: 'device/GET_DEVICES_SUCCESS',
  GET_DEVICES_FAILURE: 'device/GET_DEVICES_FAILURE',
  CONTROL_DEVICE_REQUEST: 'device/CONTROL_DEVICE_REQUEST',
  CONTROL_DEVICE_SUCCESS: 'device/CONTROL_DEVICE_SUCCESS',
  CONTROL_DEVICE_FAILURE: 'device/CONTROL_DEVICE_FAILURE'
};

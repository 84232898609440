import * as React from 'react';
import {LinearGauge, RadialGauge, LinearGaugeProps, GaugeProps} from 'react-canvas-gauges';

interface CanvasGaugeProps extends LinearGaugeProps, GaugeProps {
  value: any;
  minValue: number;
  maxValue: number;
  width: number;
  height: number;
  linear?: boolean;
  valueInt?: number;
  majorTicks?: any[];
  highlights?: any[] | null;
}

export const CanvasGauge: React.FC<CanvasGaugeProps> = (
  props: CanvasGaugeProps
) => {
  let Gauge: any;
  let gaugeProps: any;

  if (props.linear) {
    Gauge = LinearGauge;
    gaugeProps = {
      barWidth: 12,
      strokeTicks: true,
      tickSide: 'left',
      numberSide: 'left',
      animationRule: 'bounce',
      barBeginCircle: false,
      valueBoxStroke: 0,
    };
  } else {
    Gauge = RadialGauge;
    gaugeProps = {
      strokeTicks: true,
      colorPlate: '#fff',
      borderShadowWidth: 0,
      borders: false,
      needleType: 'arrow',
      needleCircleOuter: true,
      needleCircleInner: false,
      animationDuration: 1500,
      animationRule: 'linear',
      colorValueBoxBackground: '#ddd',
    };
  }

  return(
    <div className={`${!props.linear ? 'canvas-gauge' : 'canvas-linear'}`}>
      <Gauge {...gaugeProps} {...props} />
    </div>
  );
};

export default CanvasGauge;

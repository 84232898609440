import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import {Button, Card, CardBody, CardHeader} from 'reactstrap';

import {STNahmdeePreview} from './STNahmdee';
import {STWaterPumpPreview} from './STWaterPump';
import {STEnginePreview} from './STEngine';

import {Icon} from '../common';

interface DevicePreviewProps {
  device: any;
}

const DevicePreview: React.FunctionComponent<DevicePreviewProps> = (
  props: DevicePreviewProps
) => {
  const {device} = props;

  const {t} = useTranslation();
  const history = useHistory();

  let output: any = '';

  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const {id} = e.currentTarget;

      if (id === 'edit-button') {
        history.push(`/devices/${device.uuid}/edit`);
      } else {
        history.push(`/devices/${device.uuid}`);
      }
    },
    [history, device]
  );

  switch (props.device.type) {
    case 'st-waterpump':
      output = <STWaterPumpPreview device={props.device} />;
      break;
    case 'st-nahmdee':
      output = <STNahmdeePreview device={props.device} />;
      break;
    case 'st-engine':
      output = <STEnginePreview device={props.device} />;
      break;
  }

  return (
    <Card className="mb-4 pointer" onClick={handleClick}>
      <CardHeader>
        <div className="d-flex align-items-center">
          <span className="font-weight-bold mr-4">
            {t(`${device.type}_preview_header`)}
            {device && device.label && ` - ${device.label}`}
          </span>
        </div>
      </CardHeader>
      <CardBody>{output}</CardBody>
    </Card>
  );
};

DevicePreview.defaultProps = {
  device: false,
};

export default connect(null)(DevicePreview);

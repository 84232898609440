import * as React from 'react';
import {StationMap} from '../../common';

import {MapContainer} from './HomeStationsStyles';

interface HomeStationsMapProps {
  stations: any[];
  highlighted: number[];
}

export const HomeStationsMap: React.FC<HomeStationsMapProps> = (
  props: HomeStationsMapProps
) => {
  const {stations} = props;
  const positions = stations.map((s: any) => ({
    lat: s.latitude,
    lng: s.longitude,
  }));

  return (
    <MapContainer>
      <StationMap
        markers={positions}
        stations={stations}
        highlighted={props.highlighted}
      />
    </MapContainer>
  );
};

export default HomeStationsMap;
